import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import PrivateRoute from "./PrivateRoute";
import Login from "../Component/Login/Login";
import Dashboard from "../Component/Dashboard/Dashboard";
import Errorpage from "../Component/404-Error-Page/Errorpage";
import CanticateDetails from "../Component/Candicate-Details-Form/Canticate";
import FileList from "../Component/Dashboard/FileList";
import CandidateMapping from "../Component/Candicate-Details-Form/CandidateMapping";
import { ToastContainer } from "react-toastify";
function AppRouter() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route element={<AuthRoute />}>
            <Route path="/" element={<Login />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="*" element={<Errorpage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/candidate-list" element={<FileList />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/candidate-creation" element={<CanticateDetails />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/candidate-mapping" element={<CandidateMapping />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default AppRouter;
