import { createContext, useState } from "react";

export const DynamicContext = createContext();
export const DynamicProvider = ({ children }) => {
  const [logout, setlogout] = useState();
  const [otppopup, setotppopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [email, setemail] = useState();
  const [openStack, setOpenStack] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [EditData, setEditData] = useState();
  const [CanId, SetCanId] = useState();
  const [TokenExpired, setTokenExpired] = useState(false);
  const [mappingdata, setMappingData] = useState(null);

  return (
    <DynamicContext.Provider
      value={{
        logout,
        setlogout,
        otppopup,
        setotppopup,
        email,
        setemail,
        openStack,
        setOpenStack,
        Edit,
        setEdit,
        EditData,
        setEditData,
        CanId,
        SetCanId,
        isLoading,
        setIsLoading,
        TokenExpired,
        setTokenExpired,
        mappingdata,
        setMappingData,
      }}
    >
      {children}
    </DynamicContext.Provider>
  );
};
