import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { DynamicContext } from "../Context/DynamicContext";
import ExpiryScreen from "../Component/Expiry-Login-Screen/ExpiryScreen";

const PrivateRoute = () => {
  const token = localStorage.getItem("email");
  const { TokenExpired } = useContext(DynamicContext);

  if (!token) {
    return <Navigate to="/" replace />;
  }

  if (TokenExpired) {
    return <ExpiryScreen />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default PrivateRoute;
