import React, { useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as StyledDOM from "./Candidatestyle";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import axios from "axios";
import config from "../../config";
import * as url from "../assets/helpers/url_helper";
import { Toast } from "../../Common/ToastSnackBar/ToastSnackBar";
import Header from "../header/Header";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import Footerfile from "../Dashboard/Footerfile";
import downloadFileFromS3 from "../Aws-File/AwsConfig";
import Button from "../../Common/Button/Button";
import CandidateCreationActive from "../image/candidate-creation-active.png";
import { useNavigate } from "react-router-dom";
import { DynamicContext } from "../../Context/DynamicContext";
import fileUploadIcon from "../image/file-upload-icon.png";
function CanticateDetails() {
  const DynamicProps = useContext(DynamicContext);
  const navigate = useNavigate();
  const candidateDetailsRef = React.useRef(null);
  const {
    setotppopup,
    Edit,
    setEdit,
    EditData,
    CanId,
    setTokenExpired,
    mappingdata,
  } = DynamicProps || {};
  const [email, setemail] = React.useState();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [EditItem = []] = EditData || [];
  const [formStatus, setFormStatus] = React.useState(false);
  useEffect(() => {
    setemail(localStorage.getItem("email"));
  }, [email]);

  const validationSchema = Yup.object({
    CandidateEmail: Yup.string()
      .required("Email is required.")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address."
      ),
    CandidateName: Yup.string().required("Name is required."),

    ProjectStartDate: Yup.string().required(
      "Please select an project start date."
    ),
    ClientName: Yup.string().required("Client name is required."),
    countryCode: Yup.string().required("Please select any country."),
    VyVendorName: Yup.string().required("Vy vendor name is required."),
    H1BVendorornot: Yup.string()
      .oneOf(["Yes", "No"], "Please select Yes or No")
      .required("Please select an H1B vendor or not option."),
  });

  const validateForm = (values) => {
    const errors = {};

    if (values.H1BVendorornot === "No") {
      const nameValidation = Yup.string()
        .required("H1B vendor name is required.")
        .min(3, "H1B vendor name must be at least 3 characters.")
        .max(25, "H1B vendor name must be less than 25 characters.");

      try {
        nameValidation.validateSync(values.H1Bvendorname);
      } catch (error) {
        errors.H1Bvendorname = error.message;
      }
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      CandidateEmail: EditItem?.can_email || "",
      CandidateName: EditItem.can_name || "",
      ProjectStartDate: EditItem.project_start_date
        ? format(new Date(EditItem.project_start_date), "yyyy-MM-dd")
        : "",

      ClientName: EditItem.client_name || "",
      VyVendorName: EditItem.vy_vendor_name || "",
      countryCode: EditItem.country_code || "",
      H1BVendorornot: EditItem.H1BVendorornot || "",
      H1Bvendorname: EditItem.H1Bvendorname || "",

      // i9_Details:
      i9UploadFiles: EditItem.i9_file_name || [],
      i9ExpiryDate: EditItem.i9_file_expiry_date
        ? format(new Date(EditItem.i9_file_expiry_date), "yyyy-MM-dd")
        : "",
      i9Exhibit_B_Files: EditItem.exhibit_b_file_name || [],
      file: " ",

      // MSA_Details:
      MSAUploadFiles: EditItem.msa_file_name || [],
      MSAsignedDate: EditItem.file_signed_date
        ? format(new Date(EditItem.file_signed_date), "yyyy-MM-dd")
        : "",
      MSAExhibit_A_Files: EditItem.exhibit_a_file_name || [],

      // W9_Detalis:
      W9_UploadFiles: EditItem.w9_file_name || [],
      W9_VoidCheck_UploadFiles: EditItem.vc_file_name || [],
      W9_ACH_UploadFiles: EditItem.ach_file_name || [],
      W9_DataFilled: EditItem.data_filled || "",

      // Visa_Details:
      Visa_Document_UploadFiles: EditItem.visa_file_name || [],
      Visa_Company: EditItem.company_name || "",
      Visa_From_Date: EditItem.visa_file_from_date
        ? format(new Date(EditItem.visa_file_from_date), "yyyy-MM-dd")
        : "",
      Visa_Expiry_Date: EditItem.visa_file_expiry_date
        ? format(new Date(EditItem.visa_file_expiry_date), "yyyy-MM-dd")
        : "",

      // COI_Deatails:
      COI_UploadFiles: EditItem.coi_file_name || [],
      COI_Expiry_Date: EditItem.coi_file_expiry_date
        ? format(new Date(EditItem.coi_file_expiry_date), "yyyy-MM-dd")
        : "",
      COI_Expiry_Type: EditItem.coi_expiry_type || "",
      Contact_Info_Sheet_UploadFiles: EditItem.coi_cis_file_name || [],

      // Counter_sign:
      Counter_Signed_File_UploadFiles: EditItem.counter_signed_file_name || [],
      Documentation_Completion: "Incomplete",
      Remark: EditItem.remarks || "",
    },

    validationSchema: validationSchema,
    validate: validateForm,

    onSubmit: async (values, { resetForm }) => {
      const {
        CandidateEmail,
        CandidateName,
        ProjectStartDate,
        ClientName,
        VyVendorName,
        countryCode,
        H1BVendorornot,
        H1Bvendorname,
        i9UploadFiles,
        i9ExpiryDate,
        i9Exhibit_B_Files,
        MSAUploadFiles,
        MSAsignedDate,
        MSAExhibit_A_Files,
        W9_UploadFiles,
        W9_VoidCheck_UploadFiles,
        W9_ACH_UploadFiles,
        W9_DataFilled,
        Visa_Document_UploadFiles,
        Visa_Company,
        Visa_From_Date,
        Visa_Expiry_Date,
        COI_UploadFiles,
        COI_Expiry_Date,
        COI_Expiry_Type,
        Contact_Info_Sheet_UploadFiles,
        Counter_Signed_File_UploadFiles,
        Documentation_Completion,
        Remark,
      } = values;

      const getFileData = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve(event.target.result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(new Blob([file]));
        });
      };

      const getTemporaryFiles = async (fileList) => {
        const temporaryFiles = [];
        for (let i = 0; i < fileList.length; i++) {
          const file = fileList[i];
          if (file.size > 0) {
            const fileData = await getFileData(file);
            const base64Data = fileData.split(",")[1];
            temporaryFiles.push({
              fileName: file.name,
              fileData: base64Data,
              fileType: file.type,
            });
            console.log(temporaryFiles);
          }
        }
        return temporaryFiles;
      };

      const i9UploadFilesData = await getTemporaryFiles(i9UploadFiles);

      const i9Exhibit_B_FilesData = await getTemporaryFiles(i9Exhibit_B_Files);

      const MSAUploadFilesData = await getTemporaryFiles(MSAUploadFiles);
      const MSAExhibit_A_FilesData = await getTemporaryFiles(
        MSAExhibit_A_Files
      );
      const W9_UploadFilesData = await getTemporaryFiles(W9_UploadFiles);
      const W9_VoidCheck_UploadFilesData = await getTemporaryFiles(
        W9_VoidCheck_UploadFiles
      );
      const W9_ACH_UploadFilesData = await getTemporaryFiles(
        W9_ACH_UploadFiles
      );
      const Visa_Document_UploadFilesData = await getTemporaryFiles(
        Visa_Document_UploadFiles
      );
      const COI_UploadFilesData = await getTemporaryFiles(COI_UploadFiles);
      const Contact_Info_Sheet_UploadFilesData = await getTemporaryFiles(
        Contact_Info_Sheet_UploadFiles
      );
      const Counter_Signed_File_UploadFilesData = await getTemporaryFiles(
        Counter_Signed_File_UploadFiles
      );

      const Candidate_Details = [
        {
          CandidateEmail: CandidateEmail,
          CandidateName: CandidateName,
          ProjectStartDate: ProjectStartDate,
          ClientName: ClientName,
          VyVendorName: VyVendorName,
          countryCode: countryCode,
          H1BVendorornot: H1BVendorornot,
          H1Bvendorname: H1Bvendorname,
        },
      ];

      const i9_Details = [
        {
          i9UploadFiles: i9UploadFilesData,
          i9ExpiryDate: i9ExpiryDate,
          i9Exhibit_B_Files: i9Exhibit_B_FilesData,
        },
      ];
      const MSA_Details = [
        {
          MSAUploadFiles: MSAUploadFilesData,
          MSAsignedDate: MSAsignedDate,
          MSAExhibit_A_Files: MSAExhibit_A_FilesData,
        },
      ];
      const W9_Details = [
        {
          W9_UploadFiles: W9_UploadFilesData,
          W9_VoidCheck_UploadFiles: W9_VoidCheck_UploadFilesData,
          W9_ACH_UploadFiles: W9_ACH_UploadFilesData,
          W9_DataFilled: W9_DataFilled,
        },
      ];
      const Visa_Details = [
        {
          Visa_Document_UploadFiles: Visa_Document_UploadFilesData,
          Visa_Company: Visa_Company,
          Visa_From_Date: Visa_From_Date,
          Visa_Expiry_Date: Visa_Expiry_Date,
        },
      ];
      const COI_Details = [
        {
          COI_UploadFiles: COI_UploadFilesData,
          COI_Expiry_Date: COI_Expiry_Date,
          COI_Expiry_Type: COI_Expiry_Type,
          Contact_Info_Sheet_UploadFiles: Contact_Info_Sheet_UploadFilesData,
        },
      ];
      const Counter_Signed_Details = [
        {
          Counter_Signed_File_UploadFiles: Counter_Signed_File_UploadFilesData,
          Documentation_Completion: Documentation_Completion,
          Remark: Remark,
        },
      ];
      const formDatas = {
        Candidate_Details,
        i9_Details,
        MSA_Details,
        W9_Details,
        Visa_Details,
        COI_Details,
        Counter_Signed_Details,
      };
      try {
        if (Edit) {
          const response = await axios.put(
            config.apiEndpoint + url.CANDIDATE_EDIT_FORM_UPLOAD,
            formDatas,
            { params: { can_id: CanId, login_user: email } }
          );
          console.log(formDatas);
          console.log(response.data);
          setEdit(false);
          Toast("success", response.data.message);
          navigate("/candidate-list");
        } else {
          const response = await axios.post(
            config.apiEndpoint + url.CANDIDATE_FORM_UPLOAD,
            formDatas,
            {
              params: { login_user: email },
            }
          );
          console.log(response);
          Toast("success", response.data.message);
          navigate("/candidate-list");
        }
      } catch (error) {
        console.error(error.message);
        Toast("error", error.response.data.message);
        setotppopup(true);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Token expired"
        ) {
          setTokenExpired(true);
        }
      }

      console.log("formDatas", formDatas);
      resetForm({ values: "" });
    },
  });

  const AtoZPattern = (e) => {
    const pattern = /^[A-Za-z()\s\\,]+$/;
    const inputChar = String.fromCharCode(e.charCode);
    if (!pattern.test(inputChar)) {
      e.preventDefault();
    }
  };

  const handleDownload = async (fileKey) => {
    try {
      if (!fileKey) {
        console.log("File key is missing.");
        return;
      }

      const downloadLink = await downloadFileFromS3([fileKey]);

      if (downloadLink) {
        const anchor = document.createElement("a");
        anchor.href = downloadLink;
        anchor.target = "_blank";
        // anchor.style.display = "none";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      } else {
        console.log("Failed to generate download link.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const File_Edit = async (buttonType, Canid, id) => {
    try {
      const response = await axios.get(
        config.apiEndpoint + url.CANDIDATE_FILE_DETAILS,
        { params: { can_id: Canid, fileType: buttonType } }
      );
      const fetchedData = response.data.data;
      if (id === "i9") {
        handleDownload(fetchedData[0].file_key);
      } else if (id === "i9exhibit") {
        handleDownload(fetchedData[0].exhibit_b_file_key);
      } else if (id === "msa") {
        handleDownload(fetchedData[0].file_key);
      } else if (id === "msaexhibit") {
        handleDownload(fetchedData[0].exhibit_a_file_key);
      } else if (id === "w9") {
        handleDownload(fetchedData[0].file_key);
      } else if (id === "w9void") {
        handleDownload(fetchedData[0].vc_file_key);
      } else if (id === "w9ach") {
        handleDownload(fetchedData[0].ach_file_key);
      } else if (id === "visa") {
        handleDownload(fetchedData[0].file_key);
      } else if (id === "coi") {
        handleDownload(fetchedData[0].file_key);
      } else if (id === "cis") {
        handleDownload(fetchedData[0].cis_file_key);
      } else if (id === "Counter") {
        handleDownload(fetchedData[0].file_key);
      }
    } catch (error) {
      console.error(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Token expired"
      ) {
        setTokenExpired(true);
      }
    }
  };

  const handleCancelClick = (index, fieldName, inputId) => {
    const updatedFiles = [...formik.values[fieldName]];
    updatedFiles.splice(index, 1);
    formik.setFieldValue(fieldName, updatedFiles);
    const inputElement = document.getElementById(inputId);
    if (inputElement) {
      inputElement.value = "";
    }
  };
  React.useEffect(() => {
    if (mappingdata) {
      formik.setFieldValue("CandidateName", mappingdata?.can_name || "");
      formik.setFieldValue("CandidateEmail", mappingdata?.can_email || "");
      formik.setFieldValue("countryCode", mappingdata?.can_country_code || "");
    }
  }, [mappingdata]);

  useEffect(() => {
    const isFormComplete =
      formik.values.CandidateEmail !== "" &&
      formik.values.CandidateName !== "" &&
      formik.values.ProjectStartDate !== "" &&
      formik.values.ClientName !== "" &&
      formik.values.VyVendorName !== "" &&
      formik.values.countryCode !== "" &&
      (formik.values.H1BVendorornot === "Yes" ||
        (formik.values.H1BVendorornot === "No" &&
          formik.values.H1Bvendorname !== "")) &&
      formik.values.i9UploadFiles.length > 0 &&
      formik.values.i9ExpiryDate !== "" &&
      formik.values.i9Exhibit_B_Files.length > 0 &&
      formik.values.MSAUploadFiles.length > 0 &&
      formik.values.MSAsignedDate !== "" &&
      formik.values.MSAExhibit_A_Files.length > 0 &&
      formik.values.W9_UploadFiles.length > 0 &&
      formik.values.W9_VoidCheck_UploadFiles.length > 0 &&
      formik.values.W9_ACH_UploadFiles.length > 0 &&
      formik.values.Visa_Document_UploadFiles.length > 0 &&
      formik.values.Visa_From_Date !== "" &&
      formik.values.Visa_Expiry_Date !== "" &&
      formik.values.Visa_Company !== "" &&
      formik.values.COI_UploadFiles.length > 0 &&
      formik.values.COI_Expiry_Date !== "" &&
      formik.values.Contact_Info_Sheet_UploadFiles.length > 0 &&
      formik.values.Counter_Signed_File_UploadFiles.length > 0 &&
      formik.values.Remark !== "";

    formik.values.Documentation_Completion = isFormComplete
      ? "completed"
      : "incomplete";
    setFormStatus(isFormComplete);
  }, [formik.values, formik]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Header />
        <StyledDOM.MainDivContainer container innerRef={candidateDetailsRef}>
          <Grid
            item
            xs={12}
            lg={12}
            sm={12}
            style={{ marginLeft: "3%", marginRight: "3%" }}
          >
            <StyledDOM.CreateCandidateHeaderContainer className="top-container">
              <StyledDOM.HeaderInner className="header-inner">
                <StyledDOM.CreateCandidateHeaderIcon
                  src={CandidateCreationActive}
                  alt="CandidateCreation"
                  className="header-icon"
                />
                <StyledDOM.PageHeading>Create Candidate</StyledDOM.PageHeading>
              </StyledDOM.HeaderInner>

              <Button
                label="Candidate Search"
                event={() => navigate("/candidate-mapping")}
                style={{ backgroundColor: "#6c3dd3" }}
              />
            </StyledDOM.CreateCandidateHeaderContainer>
            <StyledDOM.CandidateDetailsh3>
              <StyledDOM.StyledSpanStar>*</StyledDOM.StyledSpanStar>
              Candidate Details
            </StyledDOM.CandidateDetailsh3>
            <StyledDOM.StyledCandidateMainDiv className="gird-control">
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Candidate Email ID (Pored in IRMT)
                </StyledDOM.Styledheadings>
                <StyledDOM.StyledinputEmailContainer
                  autoComplete="off"
                  type="email"
                  placeholder="Enter Your IRMT email address"
                  name="CandidateEmail"
                  className="candidateemail"
                  value={formik.values.CandidateEmail}
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    const lowercaseEmail = event.target.value.toLowerCase();
                    formik.handleChange(event);
                    formik.setFieldValue("CandidateEmail", lowercaseEmail);
                  }}
                />
                {formik.touched.CandidateEmail &&
                formik.errors.CandidateEmail ? (
                  <div className="error-message">
                    {formik.errors.CandidateEmail}
                  </div>
                ) : null}
              </StyledDOM.StyledFieldOuterDiv>

              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Candidate Name (Pored in IRMT)
                </StyledDOM.Styledheadings>
                <StyledDOM.StyledinputTextContainer
                  autoComplete="off"
                  type="text"
                  placeholder="Enter Your IRMT Name"
                  id="candidatename"
                  variant="outlined"
                  name="CandidateName"
                  onKeyPress={AtoZPattern}
                  onBlur={formik.handleBlur}
                  value={formik.values.CandidateName}
                  onChange={formik.handleChange}
                />
                {formik.touched.CandidateName && formik.errors.CandidateName ? (
                  <div className="error-message">
                    {formik.errors.CandidateName}
                  </div>
                ) : null}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Project Start Date
                </StyledDOM.Styledheadings>
                <StyledDOM.StyledDatePicker
                  name="ProjectStartDate"
                  onBlur={formik.handleBlur}
                  value={formik.values.ProjectStartDate || ""}
                  onChange={(date) => {
                    if (date) {
                      const year = date.getFullYear().toString();
                      if (year.length === 4) {
                        const formattedDate = format(date, "yyyy-MM-dd");
                        formik.setFieldValue("ProjectStartDate", formattedDate);
                      } else {
                        formik.setFieldValue("ProjectStartDate", "");
                      }
                    } else {
                      formik.setFieldValue("ProjectStartDate", "");
                    }
                  }}
                  format="dd-MM-yyyy"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                />
                {formik.touched.ProjectStartDate &&
                formik.errors.ProjectStartDate ? (
                  <div className="error-message">
                    {formik.errors.ProjectStartDate}
                  </div>
                ) : null}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Client Name
                </StyledDOM.Styledheadings>

                <StyledDOM.StyledSelectOption
                  className="client-select"
                  id="client name"
                  name="ClientName"
                  value={formik.values.ClientName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                >
                  <option value="" disabled hidden>
                    Select an option
                  </option>
                  <option value={"CodeNatives"} className="custom-select">
                    CodeNatives
                  </option>
                  <option value={"VyBog"} className="custom-select">
                    VyBog
                  </option>
                  <option value={"VySystem"} className="custom-select">
                    VySystem
                  </option>
                </StyledDOM.StyledSelectOption>

                {formik.touched.ClientName && formik.errors.ClientName ? (
                  <div className="error-message">
                    {formik.errors.ClientName}
                  </div>
                ) : null}
              </StyledDOM.StyledFieldOuterDiv>

              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Vy Vendor Name
                </StyledDOM.Styledheadings>
                <StyledDOM.StyledinputTextContainer
                  type="text"
                  name="VyVendorName"
                  placeholder="Enter Vy Vendor Name"
                  onKeyPress={AtoZPattern}
                  value={formik.values.VyVendorName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.VyVendorName && formik.errors.VyVendorName ? (
                  <div className="error-message">
                    {formik.errors.VyVendorName}
                  </div>
                ) : null}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Country
                </StyledDOM.Styledheadings>
                <StyledDOM.StyledSelectOption
                  id="countrycode"
                  name="countryCode"
                  value={formik.values.countryCode}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                >
                  <option value="" disabled hidden>
                    Select an option
                  </option>
                  <option value={1}>USA</option>
                  <option value={10}>Canada</option>
                  <option value={91}>India</option>
                  <option value={44}>United Kingdom</option>
                  <option value={65}>Singapore</option>
                  <option value={60}>Malaysia</option>
                  <option value={971}>UAE</option>
                </StyledDOM.StyledSelectOption>
                {formik.touched.countryCode && formik.errors.countryCode ? (
                  <div className="error-message">
                    {formik.errors.countryCode}
                  </div>
                ) : null}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Are they H1B vendor
                </StyledDOM.Styledheadings>

                <StyledDOM.StyledSelectOption
                  name="H1BVendorornot"
                  value={formik.values.H1BVendorornot}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                >
                  <option value="" disabled hidden>
                    Select an option
                  </option>
                  <option value={"Yes"}>Yes</option>
                  <option value={"No"}>No</option>
                </StyledDOM.StyledSelectOption>
                {formik.touched.H1BVendorornot &&
                formik.errors.H1BVendorornot ? (
                  <div className="error-message">
                    {formik.errors.H1BVendorornot}
                  </div>
                ) : null}
              </StyledDOM.StyledFieldOuterDiv>

              {formik.values.H1BVendorornot === "No" ? (
                <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                  <StyledDOM.Styledheadings className="form-heading">
                    No Means who is H1B vendor:
                  </StyledDOM.Styledheadings>
                  <StyledDOM.StyledinputTextContainer
                    type="text"
                    name="H1Bvendorname"
                    onBlur={formik.handleBlur}
                    onKeyPress={AtoZPattern}
                    value={formik.values.H1Bvendorname}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.H1Bvendorname &&
                  formik.errors.H1Bvendorname ? (
                    <div className="error-message">
                      {formik.errors.H1Bvendorname}
                    </div>
                  ) : null}
                </StyledDOM.StyledFieldOuterDiv>
              ) : null}
            </StyledDOM.StyledCandidateMainDiv>
            <StyledDOM.Note>
              <StyledDOM.StyledSpanStar>*</StyledDOM.StyledSpanStar>
              Note: select only pdf, .doc, .docx file
            </StyledDOM.Note>
            <StyledDOM.I9Detailsh3>I-9 Form Details</StyledDOM.I9Detailsh3>
            <StyledDOM.StyledCandidateMainDiv className="gird-control">
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  {" "}
                  I-9 Form Upload
                </StyledDOM.Styledheadings>
                <div className="file-input-container">
                  <label htmlFor="i9_file">
                    {" "}
                    <StyledDOM.FileUploadIcon
                      src={fileUploadIcon}
                      alt="fileUploadIcon"
                      className="fileupload-icon"
                    />{" "}
                    Choose file
                  </label>
                  <input
                    multiple
                    type="file"
                    id="i9_file"
                    placeholder="Choose file"
                    name="i9UploadFiles"
                    onChange={(e) => {
                      const newFiles = Array.from(e.target.files);
                      const filteredFiles = newFiles.filter((file) =>
                        /\.(pdf|doc|docx)$/.test(file.name)
                      );
                      formik.setFieldValue("i9UploadFiles", filteredFiles);
                      formik.setFieldValue(
                        "i9UploadFilesData",
                        filteredFiles.map((file) => ({
                          fileName: file.name,
                          fileData: URL.createObjectURL(file),
                          fileType: file.type,
                        }))
                      );
                    }}
                    onBlur={formik.handleBlur}
                  />
                </div>

                {/* {formik.errors.i9UploadFiles && formik.touched.i9UploadFiles ? (
                <div className="error-message">
                  {formik.errors.i9UploadFiles}
                </div>
              ) : null} */}
                {Edit ? (
                  <>
                    {Array.isArray(formik.values.i9UploadFiles) &&
                    formik.values.i9UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.i9UploadFiles.map((file, index) => (
                          <li key={index} className="fileupload">
                            {/* {file.name} */}
                            <a
                              href={URL.createObjectURL(file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                            <StyledDOM.StyledCancelIcon
                              className="cancel-icon"
                              onClick={() =>
                                handleCancelClick(
                                  index,
                                  "i9UploadFiles",
                                  "i9_file"
                                )
                              }
                            ></StyledDOM.StyledCancelIcon>
                          </li>
                        ))}
                      </StyledDOM.StyledOrderList>
                    ) : EditItem.i9_file_name ? (
                      EditData.length > 0 && (
                        <StyledDOM.StyledOrderList>
                          {EditData.map((file, index) =>
                            file.i9_file_name ? (
                              <li key={index} className="fileupload">
                                <span
                                  onClick={() =>
                                    File_Edit("i9", file.can_id, "i9")
                                  }
                                >
                                  {file.i9_file_name}
                                </span>

                                <StyledDOM.StyledCancelIcon className="cancel-icon"></StyledDOM.StyledCancelIcon>
                              </li>
                            ) : null
                          )}
                        </StyledDOM.StyledOrderList>
                      )
                    ) : null}
                  </>
                ) : (
                  <>
                    {Array.isArray(formik.values.i9UploadFiles) &&
                    formik.values.i9UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.i9UploadFiles.map((file, index) => (
                          <li key={index} className="fileupload">
                            {/* {file.name} */}
                            <a
                              href={URL.createObjectURL(file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>

                            <StyledDOM.StyledCancelIcon
                              className="cancel-icon"
                              onClick={() =>
                                handleCancelClick(
                                  index,
                                  "i9UploadFiles",
                                  "i9_file"
                                )
                              }
                            ></StyledDOM.StyledCancelIcon>
                          </li>
                        ))}
                      </StyledDOM.StyledOrderList>
                    ) : EditData && EditData.i9_file_name ? (
                      <StyledDOM.StyledOrderList>
                        <li className="fileupload">{EditData.i9_file_name}</li>
                      </StyledDOM.StyledOrderList>
                    ) : null}
                  </>
                )}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  {" "}
                  I-9 Expiry
                </StyledDOM.Styledheadings>
                <StyledDOM.StyledDatePicker
                  name="i9ExpiryDate"
                  onBlur={formik.handleBlur}
                  value={formik.values.i9ExpiryDate || ""}
                  onChange={(date) => {
                    if (date) {
                      const year = date.getFullYear().toString();
                      if (year.length === 4) {
                        const formattedDate = format(date, "yyyy-MM-dd");
                        formik.setFieldValue("i9ExpiryDate", formattedDate);
                      } else {
                        formik.setFieldValue("i9ExpiryDate", "");
                      }
                    } else {
                      formik.setFieldValue("i9ExpiryDate", "");
                    }
                  }}
                  format="dd-MM-yyyy"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                />
                {/* {formik.errors.i9ExpiryDate && formik.touched.i9ExpiryDate ? (
                <div className="error-message">
                  {formik.errors.i9ExpiryDate}
                </div>
              ) : null} */}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  {" "}
                  exhibit-b - vendor name/h1B filled proper
                </StyledDOM.Styledheadings>
                <div className="file-input-container">
                  <label htmlFor="i9_file-input">
                    {" "}
                    <StyledDOM.FileUploadIcon
                      src={fileUploadIcon}
                      alt="fileUploadIcon"
                      className="fileupload-icon"
                    />{" "}
                    Choose file
                  </label>
                  <input
                    multiple
                    type="file"
                    id="i9_file-input"
                    name="i9Exhibit_B_Files"
                    placeholder="Choose file"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const newFiles = Array.from(e.target.files);
                      const filteredFiles = newFiles.filter((file) =>
                        /\.(pdf|doc|docx)$/.test(file.name)
                      );
                      formik.setFieldValue("i9Exhibit_B_Files", filteredFiles);
                      formik.setFieldValue(
                        "i9Exhibit_B_FilesData",
                        filteredFiles.map((file) => ({
                          fileName: file.name,
                          fileData: URL.createObjectURL(file),
                          fileType: file.type,
                        }))
                      );
                    }}
                  />
                </div>

                {/* {formik.errors.i9Exhibit_B_Files &&
              formik.touched.i9Exhibit_B_Files ? (
                <div className="error-message">
                  {formik.errors.i9Exhibit_B_Files}
                </div>
              ) : null} */}
                {Edit ? (
                  <>
                    {Array.isArray(formik.values.i9Exhibit_B_Files) &&
                    formik.values.i9Exhibit_B_Files.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.i9Exhibit_B_Files.map((file, index) => (
                          <li key={index} className="fileupload">
                            {/* {file.name} */}
                            <a
                              href={URL.createObjectURL(file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                            <StyledDOM.StyledCancelIcon
                              className="cancel-icon"
                              onClick={() =>
                                handleCancelClick(
                                  index,
                                  "i9Exhibit_B_Files",
                                  "i9_file_input"
                                )
                              }
                            ></StyledDOM.StyledCancelIcon>
                          </li>
                        ))}
                      </StyledDOM.StyledOrderList>
                    ) : EditItem.exhibit_b_file_name ? (
                      EditData.length > 0 && (
                        <StyledDOM.StyledOrderList>
                          {EditData.map((file, index) =>
                            file.exhibit_b_file_name ? (
                              <li key={index} className="fileupload">
                                {/* {file.exhibit_b_file_name} */}
                                <span
                                  onClick={() =>
                                    File_Edit(
                                      "i9",
                                      file.can_id,

                                      "i9exhibit"
                                    )
                                  }
                                >
                                  {file.exhibit_b_file_name}
                                  <StyledDOM.StyledCancelIcon></StyledDOM.StyledCancelIcon>
                                </span>
                              </li>
                            ) : null
                          )}
                        </StyledDOM.StyledOrderList>
                      )
                    ) : null}
                  </>
                ) : (
                  <>
                    {Array.isArray(formik.values.i9Exhibit_B_Files) &&
                    formik.values.i9Exhibit_B_Files.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.i9Exhibit_B_Files.map((file, index) => (
                          <li key={index} className="fileupload">
                            {/* {file.name} */}
                            <a
                              href={URL.createObjectURL(file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                            <StyledDOM.StyledCancelIcon
                              className="cancel-icon"
                              onClick={() =>
                                handleCancelClick(
                                  index,
                                  "i9Exhibit_B_Files",
                                  "i9_file-input"
                                )
                              }
                            ></StyledDOM.StyledCancelIcon>
                          </li>
                        ))}
                      </StyledDOM.StyledOrderList>
                    ) : EditData && EditData.exhibit_b_file_name ? (
                      <StyledDOM.StyledOrderList>
                        <li className="fileupload">
                          {EditData.exhibit_b_file_name}
                        </li>
                      </StyledDOM.StyledOrderList>
                    ) : null}
                  </>
                )}
              </StyledDOM.StyledFieldOuterDiv>
            </StyledDOM.StyledCandidateMainDiv>
            <StyledDOM.MSADetailsh3>MSA Form Details</StyledDOM.MSADetailsh3>
            <StyledDOM.StyledCandidateMainDiv className="gird-control">
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  {" "}
                  MSA Upload
                </StyledDOM.Styledheadings>
                <div className="file-input-container">
                  <label htmlFor="msafile">
                    {" "}
                    <StyledDOM.FileUploadIcon
                      src={fileUploadIcon}
                      alt="fileUploadIcon"
                      className="fileupload-icon"
                    />
                    Choose file
                  </label>
                  <input
                    multiple
                    type="file"
                    id="msafile"
                    placeholder="Choose file"
                    name="MSAUploadFiles"
                    onChange={(e) => {
                      const newFiles = Array.from(e.target.files);
                      const filteredFiles = newFiles.filter((file) =>
                        /\.(pdf|doc|docx)$/.test(file.name)
                      );

                      formik.setFieldValue("MSAUploadFiles", filteredFiles);
                      formik.setFieldValue(
                        "MSAUploadFilesData ",
                        filteredFiles.map((file) => ({
                          fileName: file.name,
                          fileData: URL.createObjectURL(file),
                          fileType: file.type,
                        }))
                      );
                    }}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {/* {formik.errors.MSAUploadFiles &&
                formik.touched.MSAUploadFiles && (
                  <div className="error-message">
                    {formik.errors.MSAUploadFiles}
                  </div>
                )} */}
                {Edit ? (
                  <>
                    {Array.isArray(formik.values.MSAUploadFiles) &&
                    formik.values.MSAUploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.MSAUploadFiles.map((file, index) => (
                          <li key={index} className="fileupload">
                            {/* {file.name} */}
                            <a
                              href={URL.createObjectURL(file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                            <StyledDOM.StyledCancelIcon
                              className="cancel-icon"
                              onClick={() =>
                                handleCancelClick(
                                  index,
                                  "MSAUploadFiles",
                                  "msafile"
                                )
                              }
                            ></StyledDOM.StyledCancelIcon>
                          </li>
                        ))}
                      </StyledDOM.StyledOrderList>
                    ) : EditItem.msa_file_name ? (
                      EditData.length > 0 && (
                        <StyledDOM.StyledOrderList>
                          {EditData.map((file, index) =>
                            file.msa_file_name ? (
                              <li key={index} className="fileupload">
                                {/* {file.msa_file_name} */}
                                <span
                                  onClick={() =>
                                    File_Edit("msa", file.can_id, "msa")
                                  }
                                >
                                  {file.msa_file_name}
                                </span>
                                <StyledDOM.StyledCancelIcon></StyledDOM.StyledCancelIcon>
                              </li>
                            ) : null
                          )}
                        </StyledDOM.StyledOrderList>
                      )
                    ) : null}
                  </>
                ) : (
                  <>
                    {Array.isArray(formik.values.MSAUploadFiles) &&
                    formik.values.MSAUploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.MSAUploadFiles.map((file, index) => (
                          <li key={index} className="fileupload">
                            {/* {file.name} */}
                            <a
                              href={URL.createObjectURL(file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                            <StyledDOM.StyledCancelIcon
                              className="cancel-icon"
                              onClick={() =>
                                handleCancelClick(
                                  index,
                                  "MSAUploadFiles",
                                  "msafile"
                                )
                              }
                            ></StyledDOM.StyledCancelIcon>
                          </li>
                        ))}
                      </StyledDOM.StyledOrderList>
                    ) : EditData && EditData.msa_file_name ? (
                      <StyledDOM.StyledOrderList>
                        <li className="fileupload">
                          {EditData.msa_file_name}/ &nbsp;
                        </li>
                      </StyledDOM.StyledOrderList>
                    ) : null}
                  </>
                )}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  MSA sigened date
                </StyledDOM.Styledheadings>
                <StyledDOM.StyledDatePicker
                  name="MSAsignedDate"
                  onBlur={formik.handleBlur}
                  value={formik.values.MSAsignedDate || ""}
                  onChange={(date) => {
                    if (date) {
                      const year = date.getFullYear().toString();
                      if (year.length === 4) {
                        const formattedDate = format(date, "yyyy-MM-dd");
                        formik.setFieldValue("MSAsignedDate", formattedDate);
                      } else {
                        formik.setFieldValue("MSAsignedDate", "");
                      }
                    } else {
                      formik.setFieldValue("MSAsignedDate", "");
                    }
                  }}
                  format="dd-MM-yyyy"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                />
                {/* {formik.errors.MSAsignedDate && formik.touched.MSAsignedDate ? (
                <div className="error-message">
                  {formik.errors.MSAsignedDate}
                </div>
              ) : null} */}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Exhibit-A/PO Upload
                </StyledDOM.Styledheadings>
                <div className="file-input-container">
                  <label htmlFor="msafile-input">
                    {" "}
                    <StyledDOM.FileUploadIcon
                      src={fileUploadIcon}
                      alt="fileUploadIcon"
                      className="fileupload-icon"
                    />{" "}
                    Choose file
                  </label>
                  <input
                    multiple
                    type="file"
                    id="msafile-input"
                    name="MSAExhibit_A_Files"
                    placeholder="Choose file"
                    onChange={(e) => {
                      const newFiles = Array.from(e.target.files);
                      const filteredFiles = newFiles.filter((file) =>
                        /\.(pdf|doc|docx)$/.test(file.name)
                      );
                      formik.setFieldValue("MSAExhibit_A_Files", filteredFiles);
                      formik.setFieldValue(
                        "MSAExhibit_A_FilesData  ",
                        filteredFiles.map((file) => ({
                          fileName: file.name,
                          fileData: URL.createObjectURL(file),
                          fileType: file.type,
                        }))
                      );
                    }}
                    onBlur={formik.handleBlur}
                  />
                </div>

                {/* {formik.errors.MSAExhibit_A_Files &&
                formik.touched.MSAExhibit_A_Files && (
                  <div className="error-message">
                    {formik.errors.MSAExhibit_A_Files}
                  </div>
                )} */}
                {Edit ? (
                  <>
                    {Array.isArray(formik.values.MSAExhibit_A_Files) &&
                    formik.values.MSAExhibit_A_Files.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.MSAExhibit_A_Files.map((file, index) => (
                          <li key={index} className="fileupload">
                            {/* {file.name}/ &nbsp;{" "} */}
                            <a
                              href={URL.createObjectURL(file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                            <StyledDOM.StyledCancelIcon
                              className="cancel-icon"
                              onClick={() =>
                                handleCancelClick(
                                  index,
                                  "MSAExhibit_A_Files",
                                  "msafile-input"
                                )
                              }
                            ></StyledDOM.StyledCancelIcon>
                          </li>
                        ))}
                      </StyledDOM.StyledOrderList>
                    ) : EditItem.exhibit_a_file_name ? (
                      EditData.length > 0 && (
                        <StyledDOM.StyledOrderList>
                          {EditData.map((file, index) =>
                            file.exhibit_a_file_name ? (
                              <li key={index} className="fileupload">
                                {/* {file.exhibit_a_file_name} */}
                                <span
                                  onClick={() =>
                                    File_Edit(
                                      "msa",
                                      file.can_id,

                                      "msaexhibit"
                                    )
                                  }
                                >
                                  {file.exhibit_a_file_name}
                                </span>
                                <StyledDOM.StyledCancelIcon></StyledDOM.StyledCancelIcon>
                              </li>
                            ) : null
                          )}
                        </StyledDOM.StyledOrderList>
                      )
                    ) : null}
                  </>
                ) : (
                  <>
                    {Array.isArray(formik.values.MSAExhibit_A_Files) &&
                    formik.values.MSAExhibit_A_Files.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.MSAExhibit_A_Files.map((file, index) => (
                          <li key={index} className="fileupload">
                            {/* {file.name} */}
                            <a
                              href={URL.createObjectURL(file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                            <StyledDOM.StyledCancelIcon
                              className="cancel-icon"
                              onClick={() =>
                                handleCancelClick(
                                  index,
                                  "MSAExhibit_A_Files",
                                  "msafile-input"
                                )
                              }
                            ></StyledDOM.StyledCancelIcon>
                          </li>
                        ))}
                      </StyledDOM.StyledOrderList>
                    ) : EditData && EditData.exhibit_a_file_name ? (
                      <StyledDOM.StyledOrderList>
                        <li className="fileupload">
                          {EditData.exhibit_a_file_name}/ &nbsp;
                        </li>
                      </StyledDOM.StyledOrderList>
                    ) : null}
                  </>
                )}
              </StyledDOM.StyledFieldOuterDiv>
            </StyledDOM.StyledCandidateMainDiv>
            <StyledDOM.W9Detalish3>W9 Form Details</StyledDOM.W9Detalish3>
            <StyledDOM.StyledCandidateMainDiv className="gird-control">
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  W9 Upload
                </StyledDOM.Styledheadings>
                <div className="file-input-container">
                  <label htmlFor="w9_file">
                    {" "}
                    <StyledDOM.FileUploadIcon
                      src={fileUploadIcon}
                      alt="fileUploadIcon"
                      className="fileupload-icon"
                    />{" "}
                    Choose file
                  </label>
                  <input
                    multiple
                    type="file"
                    id="w9_file"
                    placeholder="Choose file"
                    name="W9_UploadFiles"
                    onChange={(e) => {
                      const newFiles = Array.from(e.target.files);
                      const filteredFiles = newFiles.filter((file) =>
                        /\.(pdf|doc|docx)$/.test(file.name)
                      );

                      formik.setFieldValue("W9_UploadFiles", filteredFiles);
                      formik.setFieldValue(
                        "W9_UploadFilesData   ",
                        filteredFiles.map((file) => ({
                          fileName: file.name,
                          fileData: URL.createObjectURL(file),
                          fileType: file.type,
                        }))
                      );
                    }}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {/* {formik.errors.W9_UploadFiles &&
                formik.touched.W9_UploadFiles && (
                  <div className="error-message">
                    {formik.errors.W9_UploadFiles}
                  </div>
                )} */}
                {Edit ? (
                  <>
                    {Array.isArray(formik.values.W9_UploadFiles) &&
                    formik.values.W9_UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.W9_UploadFiles.map((file, index) => (
                          <li key={index} className="fileupload">
                            {/* {file.name}/ &nbsp;{" "} */}
                            <a
                              href={URL.createObjectURL(file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                            <StyledDOM.StyledCancelIcon
                              className="cancel-icon"
                              onClick={() =>
                                handleCancelClick(
                                  index,
                                  "W9_UploadFiles",
                                  "w9_file"
                                )
                              }
                            ></StyledDOM.StyledCancelIcon>
                          </li>
                        ))}
                      </StyledDOM.StyledOrderList>
                    ) : EditItem.w9_file_name ? (
                      EditData.length > 0 && (
                        <StyledDOM.StyledOrderList>
                          {EditData.map((file, index) =>
                            file.w9_file_name ? (
                              <li key={index} className="fileupload">
                                <span
                                  onClick={() =>
                                    File_Edit("w9", file.can_id, "w9")
                                  }
                                >
                                  {file.w9_file_name}
                                </span>
                                {/* {file.w9_file_name} */}
                                <StyledDOM.StyledCancelIcon></StyledDOM.StyledCancelIcon>
                              </li>
                            ) : null
                          )}
                        </StyledDOM.StyledOrderList>
                      )
                    ) : null}
                  </>
                ) : (
                  <>
                    {Array.isArray(formik.values.W9_UploadFiles) &&
                    formik.values.W9_UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.W9_UploadFiles.map((file, index) => (
                          <li key={index} className="fileupload">
                            {/* {file.name} */}
                            <a
                              href={URL.createObjectURL(file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                            <StyledDOM.StyledCancelIcon
                              className="cancel-icon"
                              onClick={() =>
                                handleCancelClick(
                                  index,
                                  "W9_UploadFiles",
                                  "w9_file"
                                )
                              }
                            ></StyledDOM.StyledCancelIcon>
                          </li>
                        ))}
                      </StyledDOM.StyledOrderList>
                    ) : EditData && EditData.w9_file_name ? (
                      <StyledDOM.StyledOrderList>
                        <li className="fileupload">{EditData.w9_file_name}</li>
                      </StyledDOM.StyledOrderList>
                    ) : null}
                  </>
                )}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  {" "}
                  VOID Check Upload
                </StyledDOM.Styledheadings>
                <div className="file-input-container">
                  <label htmlFor="w9_file-input">
                    {" "}
                    <StyledDOM.FileUploadIcon
                      src={fileUploadIcon}
                      alt="fileUploadIcon"
                      className="fileupload-icon"
                    />
                    Choose file
                  </label>
                  <input
                    multiple
                    type="file"
                    id="w9_file-input"
                    name="W9_VoidCheck_UploadFiles"
                    placeholder="Choose file"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const newFiles = Array.from(e.target.files);
                      const filteredFiles = newFiles.filter((file) =>
                        /\.(pdf|doc|docx)$/.test(file.name)
                      );

                      formik.setFieldValue(
                        "W9_VoidCheck_UploadFiles",
                        filteredFiles
                      );
                      formik.setFieldValue(
                        "W9_VoidCheck_UploadFilesData    ",
                        filteredFiles.map((file) => ({
                          fileName: file.name,
                          fileData: URL.createObjectURL(file),
                          fileType: file.type,
                        }))
                      );
                    }}
                  />
                </div>
                {/* {formik.errors.W9_VoidCheck_UploadFiles &&
                formik.touched.W9_VoidCheck_UploadFiles && (
                  <div className="error-message">
                    {formik.errors.W9_VoidCheck_UploadFiles}
                  </div>
                )} */}
                {Edit ? (
                  <>
                    {Array.isArray(formik.values.W9_VoidCheck_UploadFiles) &&
                    formik.values.W9_VoidCheck_UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.W9_VoidCheck_UploadFiles.map(
                          (file, index) => (
                            <li key={index} className="fileupload">
                              {/* {file.name}/ &nbsp;{" "} */}
                              <a
                                href={URL.createObjectURL(file)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                              <StyledDOM.StyledCancelIcon
                                className="cancel-icon"
                                onClick={() =>
                                  handleCancelClick(
                                    index,
                                    "W9_VoidCheck_UploadFiles",
                                    "w9_file-input"
                                  )
                                }
                              ></StyledDOM.StyledCancelIcon>
                            </li>
                          )
                        )}
                      </StyledDOM.StyledOrderList>
                    ) : EditItem.vc_file_name ? (
                      EditData.length > 0 && (
                        <StyledDOM.StyledOrderList>
                          {EditData.map((file, index) =>
                            file.vc_file_name ? (
                              <li key={index} className="fileupload">
                                {/* {file.vc_file_name} */}
                                <span
                                  onClick={() =>
                                    File_Edit(
                                      "w9",
                                      file.can_id,

                                      "w9void"
                                    )
                                  }
                                >
                                  {file.vc_file_name}
                                </span>
                                <StyledDOM.StyledCancelIcon></StyledDOM.StyledCancelIcon>
                              </li>
                            ) : null
                          )}
                        </StyledDOM.StyledOrderList>
                      )
                    ) : null}
                  </>
                ) : (
                  <>
                    {Array.isArray(formik.values.W9_VoidCheck_UploadFiles) &&
                    formik.values.W9_VoidCheck_UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.W9_VoidCheck_UploadFiles.map(
                          (file, index) => (
                            <li key={index} className="fileupload">
                              {/* {file.name} */}
                              <a
                                href={URL.createObjectURL(file)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                              <StyledDOM.StyledCancelIcon
                                className="cancel-icon"
                                onClick={() =>
                                  handleCancelClick(
                                    index,
                                    "W9_VoidCheck_UploadFiles",
                                    "w9_file-input"
                                  )
                                }
                              ></StyledDOM.StyledCancelIcon>
                            </li>
                          )
                        )}
                      </StyledDOM.StyledOrderList>
                    ) : EditData && EditData.vc_file_name ? (
                      <StyledDOM.StyledOrderList>
                        <li className="fileupload">
                          {EditData.vc_file_name}/ &nbsp;
                        </li>
                      </StyledDOM.StyledOrderList>
                    ) : null}
                  </>
                )}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  {" "}
                  ACH-Upload
                </StyledDOM.Styledheadings>
                <div className="file-input-container">
                  <label htmlFor="w9_ach_file-input">
                    {" "}
                    <StyledDOM.FileUploadIcon
                      src={fileUploadIcon}
                      alt="fileUploadIcon"
                      className="fileupload-icon"
                    />{" "}
                    Choose file
                  </label>
                  <input
                    multiple
                    type="file"
                    id="w9_ach_file-input"
                    name="W9_ACH_UploadFiles"
                    placeholder="Choose file"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const newFiles = Array.from(e.target.files);
                      const filteredFiles = newFiles.filter((file) =>
                        /\.(pdf|doc|docx)$/.test(file.name)
                      );

                      formik.setFieldValue("W9_ACH_UploadFiles", filteredFiles);
                      formik.setFieldValue(
                        "W9_ACH_UploadFilesData ",
                        filteredFiles.map((file) => ({
                          fileName: file.name,
                          fileData: URL.createObjectURL(file),
                          fileType: file.type,
                        }))
                      );
                    }}
                  />
                </div>

                {/* {formik.errors.W9_ACH_UploadFiles &&
                formik.touched.W9_ACH_UploadFiles && (
                  <div className="error-message">
                    {formik.errors.W9_ACH_UploadFiles}
                  </div>
                )} */}
                {Edit ? (
                  <>
                    {Array.isArray(formik.values.W9_ACH_UploadFiles) &&
                    formik.values.W9_ACH_UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.W9_ACH_UploadFiles.map((file, index) => (
                          <li key={index} className="fileupload">
                            {/* {file.name}/ &nbsp;{" "} */}
                            <a
                              href={URL.createObjectURL(file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                            <StyledDOM.StyledCancelIcon
                              className="cancel-icon"
                              onClick={() =>
                                handleCancelClick(
                                  index,
                                  "W9_ACH_UploadFiles",
                                  "w9_ach_file-input"
                                )
                              }
                            ></StyledDOM.StyledCancelIcon>
                          </li>
                        ))}
                      </StyledDOM.StyledOrderList>
                    ) : EditItem.ach_file_name ? (
                      EditData.length > 0 && (
                        <StyledDOM.StyledOrderList>
                          {EditData.map((file, index) =>
                            file.ach_file_name ? (
                              <li key={index} className="fileupload">
                                {/* {file.ach_file_name} */}
                                <span
                                  onClick={() =>
                                    File_Edit(
                                      "w9",
                                      file.can_id,

                                      "w9ach"
                                    )
                                  }
                                >
                                  {file.ach_file_name}
                                </span>
                                <StyledDOM.StyledCancelIcon></StyledDOM.StyledCancelIcon>
                              </li>
                            ) : null
                          )}
                        </StyledDOM.StyledOrderList>
                      )
                    ) : null}
                  </>
                ) : (
                  <>
                    {Array.isArray(formik.values.W9_ACH_UploadFiles) &&
                    formik.values.W9_ACH_UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.W9_ACH_UploadFiles.map((file, index) => (
                          <li key={index} className="fileupload">
                            {/* {file.name} */}
                            <a
                              href={URL.createObjectURL(file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                            <StyledDOM.StyledCancelIcon
                              className="cancel-icon"
                              onClick={() =>
                                handleCancelClick(
                                  index,
                                  "W9_ACH_UploadFiles",
                                  "w9_ach_file-input"
                                )
                              }
                            ></StyledDOM.StyledCancelIcon>
                          </li>
                        ))}
                      </StyledDOM.StyledOrderList>
                    ) : EditData && EditData.ach_file_name ? (
                      <StyledDOM.StyledOrderList>
                        <li className="fileupload">
                          {EditData.ach_file_name}/ &nbsp;
                        </li>
                      </StyledDOM.StyledOrderList>
                    ) : null}
                  </>
                )}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  All Data Filled in ACH? With Out blank lines
                </StyledDOM.Styledheadings>

                <StyledDOM.StyledSelectOption
                  id="W9_DataFilled"
                  name="W9_DataFilled"
                  value={formik.values.W9_DataFilled}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                >
                  <option value="" disabled hidden>
                    Select an option
                  </option>
                  <option value={"yes"}>yes</option>
                  <option value={"no"}>No</option>
                </StyledDOM.StyledSelectOption>

                {/* {formik.errors.W9_DataFilled && formik.touched.W9_DataFilled && (
                <div className="error-message">
                  {formik.errors.W9_DataFilled}
                </div>
              )} */}
              </StyledDOM.StyledFieldOuterDiv>
            </StyledDOM.StyledCandidateMainDiv>
            <StyledDOM.VisaDetailsh3>Visa Type Upload</StyledDOM.VisaDetailsh3>
            <StyledDOM.StyledCandidateMainDiv className="gird-control">
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Visa Type Document Upload
                </StyledDOM.Styledheadings>
                <div className="file-input-container">
                  <label htmlFor="visafile">
                    {" "}
                    <StyledDOM.FileUploadIcon
                      src={fileUploadIcon}
                      alt="fileUploadIcon"
                      className="fileupload-icon"
                    />{" "}
                    Choose file
                  </label>
                  <input
                    multiple
                    type="file"
                    id="visafile"
                    placeholder="Choose file"
                    name="Visa_Document_UploadFiles"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const newFiles = Array.from(e.target.files);
                      const filteredFiles = newFiles.filter((file) =>
                        /\.(pdf|doc|docx)$/.test(file.name)
                      );
                      formik.setFieldValue(
                        "Visa_Document_UploadFiles",
                        filteredFiles
                      );
                      formik.setFieldValue(
                        "Visa_Document_UploadFilesData",
                        filteredFiles.map((file) => ({
                          fileName: file.name,
                          fileData: URL.createObjectURL(file),
                          fileType: file.type,
                        }))
                      );
                    }}
                  />
                </div>

                {/* {formik.errors.Visa_Document_UploadFiles &&
                formik.touched.Visa_Document_UploadFiles && (
                  <div className="error-message">
                    {formik.errors.Visa_Document_UploadFiles}
                  </div>
                )} */}
                {Edit ? (
                  <>
                    {Array.isArray(formik.values.Visa_Document_UploadFiles) &&
                    formik.values.Visa_Document_UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.Visa_Document_UploadFiles.map(
                          (file, index) => (
                            <li key={index} className="fileupload">
                              {/* {file.name}/ &nbsp;{" "} */}
                              <a
                                href={URL.createObjectURL(file)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                              <StyledDOM.StyledCancelIcon
                                className="cancel-icon"
                                onClick={() =>
                                  handleCancelClick(
                                    index,
                                    "Visa_Document_UploadFiles",
                                    "Visa_Document_UploadFiles"
                                  )
                                }
                              ></StyledDOM.StyledCancelIcon>
                            </li>
                          )
                        )}
                      </StyledDOM.StyledOrderList>
                    ) : EditItem.visa_file_name ? (
                      EditData.length > 0 && (
                        <StyledDOM.StyledOrderList>
                          {EditData.map((file, index) =>
                            file.visa_file_name ? (
                              <li key={index} className="fileupload">
                                {/* {file.visa_file_name} */}
                                <span
                                  onClick={() =>
                                    File_Edit("visa", file.can_id, "visa")
                                  }
                                >
                                  {file.visa_file_name}
                                </span>
                                <StyledDOM.StyledCancelIcon></StyledDOM.StyledCancelIcon>
                              </li>
                            ) : null
                          )}
                        </StyledDOM.StyledOrderList>
                      )
                    ) : null}
                  </>
                ) : (
                  <>
                    {Array.isArray(formik.values.Visa_Document_UploadFiles) &&
                    formik.values.Visa_Document_UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.Visa_Document_UploadFiles.map(
                          (file, index) => (
                            <li key={index} className="fileupload">
                              {/* {file.name} */}
                              <a
                                href={URL.createObjectURL(file)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                              <StyledDOM.StyledCancelIcon
                                className="cancel-icon"
                                onClick={() =>
                                  handleCancelClick(
                                    index,
                                    "Visa_Document_UploadFiles",
                                    "Visa_Document_UploadFiles"
                                  )
                                }
                              ></StyledDOM.StyledCancelIcon>
                            </li>
                          )
                        )}
                      </StyledDOM.StyledOrderList>
                    ) : EditData && EditData.visa_file_name ? (
                      <StyledDOM.StyledOrderList>
                        <li className="fileupload">
                          {EditData.visa_file_name}/ &nbsp;
                        </li>
                      </StyledDOM.StyledOrderList>
                    ) : null}
                  </>
                )}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  {" "}
                  Visa Company/this has to be crosed verified with the above H1B
                  Company.
                </StyledDOM.Styledheadings>
                <StyledDOM.StyledinputTextContainer
                  id="VisaCompany"
                  name="Visa_Company"
                  placeholder="Enter Visa Company Name"
                  onKeyPress={AtoZPattern}
                  value={formik.values.Visa_Company}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {/* {formik.errors.Visa_Company && formik.touched.Visa_Company && (
                <div className="error-message">
                  {formik.errors.Visa_Company}
                </div>
              )} */}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Visa From Date
                </StyledDOM.Styledheadings>
                <StyledDOM.StyledDatePicker
                  name="Visa_From_Date"
                  onBlur={formik.handleBlur}
                  value={formik.values.Visa_From_Date || ""}
                  onChange={(date) => {
                    if (date) {
                      const year = date.getFullYear().toString();
                      if (year.length === 4) {
                        const formattedDate = format(date, "yyyy-MM-dd");
                        formik.setFieldValue("Visa_From_Date", formattedDate);
                      } else {
                        formik.setFieldValue("Visa_From_Date", "");
                      }
                    } else {
                      formik.setFieldValue("Visa_From_Date", "");
                    }
                  }}
                  format="dd-MM-yyyy"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                />
                {/* {formik.errors.Visa_From_Date &&
                formik.touched.Visa_From_Date && (
                  <div className="error-message">
                    {formik.errors.Visa_From_Date}
                  </div>
                )} */}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Visa Expiry/End Date
                </StyledDOM.Styledheadings>
                <StyledDOM.StyledDatePicker
                  name="Visa_Expiry_Date"
                  onBlur={formik.handleBlur}
                  value={formik.values.Visa_Expiry_Date || ""}
                  onChange={(date) => {
                    if (date) {
                      const year = date.getFullYear().toString();
                      if (year.length === 4) {
                        const formattedDate = format(date, "yyyy-MM-dd");
                        formik.setFieldValue("Visa_Expiry_Date", formattedDate);
                      } else {
                        formik.setFieldValue("Visa_Expiry_Date", "");
                      }
                    } else {
                      formik.setFieldValue("Visa_Expiry_Date", "");
                    }
                  }}
                  format="dd-MM-yyyy"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                />
                {/* {formik.errors.Visa_Expiry_Date &&
                formik.touched.Visa_Expiry_Date && (
                  <div className="error-message">
                    {formik.errors.Visa_Expiry_Date}
                  </div>
                )} */}
              </StyledDOM.StyledFieldOuterDiv>
            </StyledDOM.StyledCandidateMainDiv>
            <StyledDOM.COIDeatailsh3>
              Insuraunce Type of Certificate Upload
            </StyledDOM.COIDeatailsh3>
            <StyledDOM.StyledCandidateMainDiv className="gird-control">
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Certificate of insuraunce Upload
                </StyledDOM.Styledheadings>
                <div className="file-input-container">
                  <label htmlFor="coifile">
                    {" "}
                    <StyledDOM.FileUploadIcon
                      src={fileUploadIcon}
                      alt="fileUploadIcon"
                      className="fileupload-icon"
                    />
                    Choose file
                  </label>
                  <input
                    multiple
                    type="file"
                    id="coifile"
                    placeholder="Choose file"
                    name="COI_UploadFiles"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const newFiles = Array.from(e.target.files);
                      const filteredFiles = newFiles.filter((file) =>
                        /\.(pdf|doc|docx)$/.test(file.name)
                      );

                      formik.setFieldValue("COI_UploadFiles", filteredFiles);
                      formik.setFieldValue(
                        "COI_UploadFilesData       ",
                        filteredFiles.map((file) => ({
                          fileName: file.name,
                          fileData: URL.createObjectURL(file),
                          fileType: file.type,
                        }))
                      );
                    }}
                  />
                </div>

                {/* {formik.errors.COI_UploadFiles &&
                formik.touched.COI_UploadFiles && (
                  <div className="error-message">
                    {formik.errors.COI_UploadFiles}
                  </div>
                )} */}
                {Edit ? (
                  <>
                    {Array.isArray(formik.values.COI_UploadFiles) &&
                    formik.values.COI_UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.COI_UploadFiles.map((file, index) => (
                          <li key={index} className="fileupload">
                            {/* {file.name} */}
                            <a
                              href={URL.createObjectURL(file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                            <StyledDOM.StyledCancelIcon
                              className="cancel-icon"
                              onClick={() =>
                                handleCancelClick(
                                  index,
                                  "COI_UploadFiles",
                                  "coifile"
                                )
                              }
                            ></StyledDOM.StyledCancelIcon>
                          </li>
                        ))}
                      </StyledDOM.StyledOrderList>
                    ) : EditItem.coi_file_name ? (
                      EditData.length > 0 && (
                        <StyledDOM.StyledOrderList>
                          {EditData.map((file, index) =>
                            file.coi_file_name ? (
                              <li key={index} className="fileupload">
                                {/* {file.coi_file_name} */}
                                <span
                                  onClick={() =>
                                    File_Edit("coi", file.can_id, "coi")
                                  }
                                >
                                  {file.coi_file_name}
                                </span>
                                <StyledDOM.StyledCancelIcon></StyledDOM.StyledCancelIcon>
                              </li>
                            ) : null
                          )}
                        </StyledDOM.StyledOrderList>
                      )
                    ) : null}
                  </>
                ) : (
                  <>
                    {Array.isArray(formik.values.COI_UploadFiles) &&
                    formik.values.COI_UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.COI_UploadFiles.map((file, index) => (
                          <li key={index} className="fileupload">
                            {/* {file.name} */}
                            <a
                              href={URL.createObjectURL(file)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                            </a>
                            <StyledDOM.StyledCancelIcon
                              className="cancel-icon"
                              onClick={() =>
                                handleCancelClick(
                                  index,
                                  "COI_UploadFiles",
                                  "coifile"
                                )
                              }
                            ></StyledDOM.StyledCancelIcon>
                          </li>
                        ))}
                      </StyledDOM.StyledOrderList>
                    ) : EditData && EditData.coi_file_name ? (
                      <StyledDOM.StyledOrderList>
                        <li className="fileupload">{EditData.coi_file_name}</li>
                      </StyledDOM.StyledOrderList>
                    ) : null}
                  </>
                )}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  COI Expiry least
                </StyledDOM.Styledheadings>

                <StyledDOM.StyledDatePicker
                  name="COI_Expiry_Date"
                  onBlur={formik.handleBlur}
                  value={formik.values.COI_Expiry_Date || ""}
                  onChange={(date) => {
                    if (date) {
                      const year = date.getFullYear().toString();
                      if (year.length === 4) {
                        const formattedDate = format(date, "yyyy-MM-dd");
                        formik.setFieldValue("COI_Expiry_Date", formattedDate);
                      } else {
                        formik.setFieldValue("COI_Expiry_Date", "");
                      }
                    } else {
                      formik.setFieldValue("COI_Expiry_Date", "");
                    }
                  }}
                  format="dd-MM-yyyy"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                />

                {/* {formik.errors.COI_Expiry_Date &&
                formik.touched.COI_Expiry_Date && (
                  <div className="error-message">
                    {formik.errors.COI_Expiry_Date}
                  </div>
                )} */}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Contact info Sheet Upload
                </StyledDOM.Styledheadings>
                <div className="file-input-container">
                  <label htmlFor="contactfile">
                    {" "}
                    <StyledDOM.FileUploadIcon
                      src={fileUploadIcon}
                      alt="fileUploadIcon"
                      className="fileupload-icon"
                    />{" "}
                    Choose file
                  </label>
                  <input
                    multiple
                    type="file"
                    id="contactfile"
                    placeholder="Choose file"
                    name="Contact_Info_Sheet_UploadFiles"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const newFiles = Array.from(e.target.files);
                      const filteredFiles = newFiles.filter((file) =>
                        /\.(pdf|doc|docx)$/.test(file.name)
                      );

                      formik.setFieldValue(
                        "Contact_Info_Sheet_UploadFiles",
                        filteredFiles
                      );
                      formik.setFieldValue(
                        "Contact_Info_Sheet_UploadFilesData",
                        filteredFiles.map((file) => ({
                          fileName: file.name,
                          fileData: URL.createObjectURL(file),
                          fileType: file.type,
                        }))
                      );
                    }}
                  />
                </div>

                {/* {formik.errors.Contact_Info_Sheet_UploadFiles &&
                formik.touched.Contact_Info_Sheet_UploadFiles && (
                  <div className="error-message">
                    {formik.errors.Contact_Info_Sheet_UploadFiles}
                  </div>
                )} */}
                {Edit ? (
                  <>
                    {Array.isArray(
                      formik.values.Contact_Info_Sheet_UploadFiles
                    ) &&
                    formik.values.Contact_Info_Sheet_UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.Contact_Info_Sheet_UploadFiles.map(
                          (file, index) => (
                            <li key={index} className="fileupload">
                              {/* {file.name} */}
                              <a
                                href={URL.createObjectURL(file)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                                <StyledDOM.StyledCancelIcon
                                  className="cancel-icon"
                                  onClick={() =>
                                    handleCancelClick(
                                      index,
                                      "Contact_Info_Sheet_UploadFiles",
                                      "contactfile"
                                    )
                                  }
                                ></StyledDOM.StyledCancelIcon>
                              </a>
                            </li>
                          )
                        )}
                      </StyledDOM.StyledOrderList>
                    ) : EditItem.coi_cis_file_name ? (
                      EditData.length > 0 && (
                        <StyledDOM.StyledOrderList>
                          {EditData.map((file, index) =>
                            file.coi_cis_file_name ? (
                              <li key={index} className="fileupload">
                                <span
                                  onClick={() =>
                                    File_Edit("coi", file.can_id, "cis")
                                  }
                                >
                                  {file.coi_cis_file_name}
                                </span>
                                {/* {file.coi_cis_file_name} */}
                                <StyledDOM.StyledCancelIcon></StyledDOM.StyledCancelIcon>
                              </li>
                            ) : null
                          )}
                        </StyledDOM.StyledOrderList>
                      )
                    ) : null}
                  </>
                ) : (
                  <>
                    {Array.isArray(
                      formik.values.Contact_Info_Sheet_UploadFiles
                    ) &&
                    formik.values.Contact_Info_Sheet_UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.Contact_Info_Sheet_UploadFiles.map(
                          (file, index) => (
                            <li key={index} className="fileupload">
                              {/* {file.name} */}
                              <a
                                href={URL.createObjectURL(file)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                              <StyledDOM.StyledCancelIcon
                                className="cancel-icon"
                                onClick={() =>
                                  handleCancelClick(
                                    index,
                                    "Contact_Info_Sheet_UploadFiles",
                                    "contactfile"
                                  )
                                }
                              ></StyledDOM.StyledCancelIcon>
                            </li>
                          )
                        )}
                      </StyledDOM.StyledOrderList>
                    ) : EditData && EditData.coi_cis_file_name ? (
                      <StyledDOM.StyledOrderList>
                        <li className="fileupload">
                          {EditData.coi_cis_file_name}/ &nbsp;
                        </li>
                      </StyledDOM.StyledOrderList>
                    ) : null}
                  </>
                )}
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Type of insuraunce-Nearing Expiry
                </StyledDOM.Styledheadings>
                <StyledDOM.StyledTextArea
                  id="coi_expiry-type"
                  name="COI_Expiry_Type"
                  onKeyPress={AtoZPattern}
                  value={formik.values.COI_Expiry_Type}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {/* {formik.errors.COI_Expiry_Type &&
                formik.touched.COI_Expiry_Type && (
                  <div className="error-message">
                    {formik.errors.COI_Expiry_Type}
                  </div>
                )} */}
              </StyledDOM.StyledFieldOuterDiv>
            </StyledDOM.StyledCandidateMainDiv>
            <StyledDOM.CountersignDeatailsh3>
              Countersigned Type Upload
            </StyledDOM.CountersignDeatailsh3>
            <StyledDOM.StyledCandidateMainDiv className="gird-control">
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Countersigned from Vy to Vendor- Upload
                </StyledDOM.Styledheadings>
                <div className="file-input-container">
                  <label htmlFor="counterfile">
                    {" "}
                    <StyledDOM.FileUploadIcon
                      src={fileUploadIcon}
                      alt="fileUploadIcon"
                      className="fileupload-icon"
                    />
                    Choose file
                  </label>
                  <input
                    multiple
                    type="file"
                    id="counterfile"
                    placeholder="Choose file"
                    name="Counter_Signed_File_UploadFiles"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const newFiles = Array.from(e.target.files);
                      const filteredFiles = newFiles.filter((file) =>
                        /\.(pdf|doc|docx)$/.test(file.name)
                      );

                      formik.setFieldValue(
                        "Counter_Signed_File_UploadFiles",
                        filteredFiles
                      );
                      formik.setFieldValue(
                        "Counter_Signed_File_UploadFilesData",
                        filteredFiles.map((file) => ({
                          fileName: file.name,
                          fileData: URL.createObjectURL(file),
                          fileType: file.type,
                        }))
                      );
                    }}
                  />
                </div>

                {/* {formik.errors.Counter_Signed_File_UploadFiles &&
                formik.touched.Counter_Signed_File_UploadFiles && (
                  <div className="error-message">
                    {formik.errors.Counter_Signed_File_UploadFiles}
                  </div>
                )} */}
                {Edit ? (
                  <>
                    {Array.isArray(
                      formik.values.Counter_Signed_File_UploadFiles
                    ) &&
                    formik.values.Counter_Signed_File_UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.Counter_Signed_File_UploadFiles.map(
                          (file, index) => (
                            <li key={index} className="fileupload">
                              {/* {file.name} */}
                              <a
                                href={URL.createObjectURL(file)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                            </li>
                          )
                        )}
                      </StyledDOM.StyledOrderList>
                    ) : EditItem.counter_signed_file_name ? (
                      EditData.length > 0 && (
                        <StyledDOM.StyledOrderList>
                          {EditData.map((file, index) =>
                            file.counter_signed_file_name ? (
                              <li key={index} className="fileupload">
                                {/* {file.counter_signed_file_name} */}
                                <span
                                  onClick={() =>
                                    File_Edit(
                                      "counter_sign",
                                      file.can_id,

                                      "Counter"
                                    )
                                  }
                                >
                                  {file.counter_signed_file_name}
                                </span>
                                <StyledDOM.StyledCancelIcon></StyledDOM.StyledCancelIcon>
                              </li>
                            ) : null
                          )}
                        </StyledDOM.StyledOrderList>
                      )
                    ) : null}
                  </>
                ) : (
                  <>
                    {Array.isArray(
                      formik.values.Counter_Signed_File_UploadFiles
                    ) &&
                    formik.values.Counter_Signed_File_UploadFiles.length > 0 ? (
                      <StyledDOM.StyledOrderList>
                        {formik.values.Counter_Signed_File_UploadFiles.map(
                          (file, index) => (
                            <li key={index} className="fileupload">
                              {/* {file.name} */}
                              <a
                                href={URL.createObjectURL(file)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                              <StyledDOM.StyledCancelIcon
                                className="cancel-icon"
                                onClick={() =>
                                  handleCancelClick(
                                    index,
                                    "Counter_Signed_File_UploadFiles",
                                    "counterfile"
                                  )
                                }
                              ></StyledDOM.StyledCancelIcon>
                            </li>
                          )
                        )}
                      </StyledDOM.StyledOrderList>
                    ) : EditData && EditData.counter_signed_file_name ? (
                      <StyledDOM.StyledOrderList>
                        <li className="fileupload">
                          {EditData.counter_signed_file_name}/ &nbsp;
                        </li>
                      </StyledDOM.StyledOrderList>
                    ) : null}
                  </>
                )}
              </StyledDOM.StyledFieldOuterDiv>

              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Documentation Completion{" "}
                </StyledDOM.Styledheadings>
                <StyledDOM.StyledDocumantaionContainer
                  disabled
                  name="Documentation_Completion"
                  value={
                    formStatus
                      ? (formik.values.Documentation_Completion = "Completed")
                      : (formik.values.Documentation_Completion = "Incomplete")
                  }
                  as={
                    formStatus
                      ? StyledDOM.StyledinputTextContainerComplete
                      : StyledDOM.StyledinputTextContainerIncomplete
                  }
                  onChange={formik.handleChange}
                />
              </StyledDOM.StyledFieldOuterDiv>
              <StyledDOM.StyledFieldOuterDiv className="field-outer-div">
                <StyledDOM.Styledheadings className="form-heading">
                  Remark/What's pending/Why correct status
                </StyledDOM.Styledheadings>
                <StyledDOM.StyledTextArea
                  id="outlined-multiline-flexible"
                  multiline
                  maxRows={6}
                  onKeyPress={AtoZPattern}
                  value={formik.values.Remark}
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue("Remark", e.target.value)
                  }
                />
                {/* {formik.errors.Remark && formik.touched.Remark && (
                <div className="error-message">{formik.errors.Remark}</div>
              )} */}
              </StyledDOM.StyledFieldOuterDiv>
            </StyledDOM.StyledCandidateMainDiv>
            {/* {formik.errors.CandidateName ||
              formik.errors.CandidateEmail ||
              formik.errors.ClientName ||
              formik.errors.H1BVendorornot ||
              formik.errors.H1Bvendorname ||
              formik.errors.countryCode ||
              formik.errors.VyVendorName ||
              formik.errors.ProjectStartDate ? (
                <p>Please fill the Candidate Details</p>
              ) : null} */}

            <StyledDOM.StyledButtonDiv>
              <StyledDOM.StyledFileUploadButton
                className="upload-button"
                onClick={() => scrollToTop()}
                type="submit"
              >
                Upload
              </StyledDOM.StyledFileUploadButton>
            </StyledDOM.StyledButtonDiv>
          </Grid>
          <Footerfile />
        </StyledDOM.MainDivContainer>
      </form>
    </>
  );
}

export default CanticateDetails;
