import React, { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import config from "../../config";
import * as url from "../assets/helpers/url_helper";
import Otppage from "../OTP Page/Otp";
import { TextField } from "@mui/material";
import loginlogo from "../image/vy-logo.png";
import Lock from "../image/login-icon.png";
import "../styles/login.css";
import LoginAnimation from "../../Component/assets/Json/login-animation.json";
import LeftSideBanner from "../../Common/LeftSideBanner.jsx";
import * as StyledDOM from "./Login.jsx";
import PoweredBy from "./Footer.js";
import { Toast } from "../../Common/ToastSnackBar/ToastSnackBar.js";
import { DynamicContext } from "../../Context/DynamicContext.js";
function Login() {
  const DynamicProps = useContext(DynamicContext);
  const { otppopup, setotppopup, setemail, setIsLoading } = DynamicProps || {};

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      ),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setemail(formik.values.email);
      try {
        const response = await axios.post(
          config.apiEndpoint + url.OTP_GENERATE,
          {
            userEmail: values.email,
          }
        );
        console.log(response);
        setotppopup(true);
        setIsLoading(false);
        Toast("success", response.data.message);
      } catch (error) {
        setotppopup(false);
        setIsLoading(false);
        Toast("error", error.response.data.message);
      }
    },
  });

  return (
    <>
      <StyledDOM.StyledCreateAccountMainContainer className="create-account-main-container">
        <LeftSideBanner playerSrc={LoginAnimation} />
        {otppopup ? (
          <Otppage />
        ) : (
          <StyledDOM.StyledCreateAccountRightContainer className="create-account-right-container">
            <StyledDOM.StyledRightFormContainer className="create-account-form-container">
              <StyledDOM.StyledVybogLogo
                className="vybog-logo"
                src={loginlogo}
              />

              <div className="vylogo">
                <img src={Lock} alt="logo" />
              </div>
              <StyledDOM.StyledCreateAccountHeading className="create-account-heading-text">
                Login Your Account
              </StyledDOM.StyledCreateAccountHeading>
              <StyledDOM.StyledCreateAccountForm
                className="create-account-form"
                onSubmit={formik.handleSubmit}
              >
                <div className="login_box">
                  <label htmlFor="email">Email</label>
                  <TextField
                    style={{ marginTop: "10px" }}
                    fullWidth
                    autoComplete="email"
                    className="login-email"
                    id="email"
                    type="email"
                    onChange={(event) => {
                      const lowercaseEmail = event.target.value.toLowerCase();
                      formik.handleChange(event);
                      formik.setFieldValue("email", lowercaseEmail);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    name="email"
                    placeholder="example@gmail.com"
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="errormsg">{formik.errors.email}</div>
                  ) : null}
                </div>

                <button type="submit" className="submit">
                  Login
                </button>
              </StyledDOM.StyledCreateAccountForm>
              <PoweredBy />
            </StyledDOM.StyledRightFormContainer>
          </StyledDOM.StyledCreateAccountRightContainer>
        )}
      </StyledDOM.StyledCreateAccountMainContainer>
    </>
  );
}
export default Login;
