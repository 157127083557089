import styled from "@emotion/styled";

export const StyledCreateAccountMainContainer = styled("div")({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
    height: "100vh",
    "@media screen and (min-width:300px) and (max-width:768px)": {
      gridTemplateColumns: "1fr",
    },
    "@media screen and (min-width:769px) and (max-width:920px)": {
      gridTemplateColumns: "1fr",
    },
  });

  export const StyledCreateAccountRightContainer = styled("div")({
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    // marginTop:"6%",
    "@media screen and (min-width:300px) and (max-width:768px)": {
      alignItems: "center",
    },
    "@media screen and (min-width:769px) and (max-width:920px)": {
      alignItems: "center",
    },
  });

  export const StyledRightFormContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "0 20%",
    height:"100vh",
    justifyContent: "center",
    alignItems: "center",
    "@media screen and (min-width:300px) and (max-width:590px)": {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    "@media screen and (min-width:591px) and (max-width:650px)": {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    "@media screen and (min-width:769px) and (max-width:920px)": {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
  });

  export const StyledVybogLogo = styled("img")({
    width: "55%",
    alignSelf: "center",
    marginBottom:"2vw",
    "@media screen and (min-width:300px) and (max-width:500px)": {
      alignItems: "center",
      marginTop: "14vw",
    },
    "@media screen and (min-width:501px) and (max-width:768px)": {
      alignItems: "center",
      marginTop: "10vw",
    },
  });

  export const StyledCreateAccountHeading = styled("h2")({
    // fontSize: "16px",
    fontWeight: "500",
    textAlign: "center",
    // color: "#0D52A7",
    marginBottom: "5vh",
    "@media screen and (max-width:400px)": {
      fontSize: "14px",
    },
  });

  export const StyledCreateAccountForm = styled("form")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  });

