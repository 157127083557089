import React from "react";
import styled from "@emotion/styled";

export const StyledButton = styled("button")(
  ({
    backgroundColor,
    color,
    border,
    hoverbackgroundColor,
    hoverColor,
    hoverBorder,
  }) => ({
    backgroundColor: backgroundColor || "#0D52A7",
    color: color || "white",
    padding: "12px 30px",
    border: border || "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontWeight: "600",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
    position: "relative",

    "&:hover": {
      backgroundColor: hoverbackgroundColor,
      color: hoverColor,
      border: hoverBorder || "none",
    },
  })
);

export const ExportButton = styled(StyledButton)({
  backgroundColor: "#EEF8F1",
  color: "#216E3A",
  border: "1px solid #216E3A",
});

export const CancelButton = styled(StyledButton)({
  backgroundColor: "#F5F4F3",
  border: "1px solid #ACACAC",
  color: "#7B7A7A",
  marginRight: "25px",
});

export const StyledIcon = styled("img")({
  width: "20px",
});

function Button(props) {
  const {
    event = () => "",
    type = "button",
    label = "",
    className = "",
    loading = false,
    customLoading = false,
    disabled = false,
    isCancel = false,
    style = {},
    iconSrc = null,
    iconPosition = "left",
    isExport = false,
    backgroundColor = "",
    color = "",
    border = "",
    hoverbackgroundColor = "",
    hoverColor = "",
    hoverBorder = "",
  } = props || {};

  const ButtonComponent = isExport
    ? ExportButton
    : isCancel
    ? CancelButton
    : StyledButton;

  return (
    <ButtonComponent
      onClick={event}
      type={type}
      className={className}
      style={{
        ...style,
        cursor: loading || customLoading ? "no-drop" : "pointer",
      }}
      disabled={disabled}
      backgroundColor={backgroundColor}
      color={color}
      border={border}
      hoverbackgroundColor={hoverbackgroundColor}
      hoverColor={hoverColor}
      hoverBorder={hoverBorder}
    >
      {iconSrc && iconPosition === "left" && (
        <StyledIcon src={iconSrc} alt="icon" />
      )}
      {label}
      {iconSrc && iconPosition === "right" && (
        <StyledIcon src={iconSrc} alt="icon" />
      )}
    </ButtonComponent>
  );
}

export default Button;
