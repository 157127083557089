import React, { useState, useContext } from "react";
import { useFormik } from "formik";
import * as StyledDOM from "./Candidatestyle";
import axios from "axios";
import * as url from "../assets/helpers/url_helper";
import { Toast } from "../../Common/ToastSnackBar/ToastSnackBar";
import Header from "../header/Header";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import ExpiryScreen from "../Expiry-Login-Screen/ExpiryScreen";
import Footerfile from "../Dashboard/Footerfile";
import DynamicTextField from "../../Common/TextField/Textfield";
import Button from "../../Common/Button/Button";
import DynamicSelectInput from "../../Common/Select-Input/SelectInput";
import SearchIcon from "../image/search-icon.png";
import CustomTooltip from "../../Common/Tooltip/Tooltip";
import buttonSearchIcon from "../image/button-search.png";
import buttonClearIcon from "../image/button-clear.png";
import Table from "../../Common/Table/Table";
import Loader from "../Loader/Loader";
import styled from "@emotion/styled";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import CandidateSearchAnimation from "../assets/Json/candidate-search-animation.json";
import CandidateMappingIcon from "../image/candidate-mapping-active.png";
import { useNavigate } from "react-router-dom";
import { DynamicContext } from "../../Context/DynamicContext";

const StyledAnimationContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
});
function CandidateMapping() {
  const DynamicProps = useContext(DynamicContext);
  const navigate = useNavigate();
  const { TokenExpired, setTokenExpired, setMappingData } = DynamicProps || {};
  const [getSuccessMessage, setGetSuccessMessage] = useState(null);
  const [IRMTFilterData, setIRMTFilterData] = useState([]);

  const [loaderIRMT, setloaderIRMT] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const userEmail = localStorage.getItem("email");
  console.log("IRMTFilterData", IRMTFilterData);

  const CountryOptions = [
    { title: "USA", value: 1 },
    { title: "Canada", value: 10 },
  ];
  const searchFormik = useFormik({
    initialValues: {
      canName: "",
      canEmail: "",
      country: "",
    },
  });
  const column = [
    {
      name: "Name",
      selector: (row) => (
        <CustomTooltip title={row?.can_name}>
          <span>{row?.can_name}</span>
        </CustomTooltip>
      ),
    },
    {
      name: "Email",
      selector: (row) => (
        <CustomTooltip title={row?.can_email}>
          <span>{row?.can_email}</span>
        </CustomTooltip>
      ),
    },
    {
      name: "Phone Number",
      selector: (row) => <span>{row?.can_phone}</span>,
    },
    {
      name: "Country",
      selector: (row) => <span>{row?.can_country_name}</span>,
    },
    // {
    //   name: "Skill",
    //   selector: (row) => <span>{row?.can_skill}</span>,
    // },
    {
      name: "Role",
      selector: (row) => <span>{row?.role}</span>,
    },
    {
      name: "Mapping",
      selector: (row, index) => (
        <Button
          label={"Mapping"}
          style={{ backgroundColor: "#7f56d9", padding: "10px" }}
          event={() => handleMappingClick(index)}
        />
      ),
    },
  ];

  const handleMappingClick = (index) => {
    const selectedObject = IRMTFilterData[index];
    setMappingData(selectedObject);
    navigate("/candidate-creation");
  };

  const handleCreateCandidate = () => {
    navigate("/candidate-creation");
    setMappingData(null);
  };

  const handleFilter = async () => {
    if (
      searchFormik.values.canName ||
      searchFormik.values.canEmail ||
      searchFormik.values.country !== ""
    ) {
      setloaderIRMT(true);
      try {
        const response = await axios.get(
          "https://vyhire.com/ajax" + url.CANDIDATE_FILTER_VALUES,
          {
            params: {
              user_key: userEmail,
              can_name: searchFormik.values.canName,
              can_email: searchFormik.values.canEmail,
              country: searchFormik.values.country,
            },
          }
        );
        const filteredData = response?.data?.results?.candidates;
        const message = response.data.results.message;
        setIRMTFilterData(filteredData);
        console.log("handleFilter error message", message);
        Toast(
          message === "Candidates not found matching the criteria."
            ? "error"
            : "success",
          message
        );
        setGetSuccessMessage(message);

        setCurrentPage(0);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Token expired"
        ) {
          setTokenExpired(true);
        }
      } finally {
        setloaderIRMT(false);
        searchFormik.resetForm();
      }
    } else {
      Toast("error", "Please enter any search values");
    }
  };

  if (loaderIRMT) {
    return <Loader />;
  }
  return (
    <>
      {TokenExpired ? (
        <ExpiryScreen />
      ) : (
        <>
          <Header />
          <>
            <StyledDOM.HeaderContainer className="top-container">
              <StyledDOM.HeaderInner className="header-inner">
                <StyledDOM.HeaderIcon
                  src={CandidateMappingIcon}
                  alt="CandidateMappingIcon"
                  className="header-icon"
                />
                <StyledDOM.PageHeading>Candidate Mapping</StyledDOM.PageHeading>
              </StyledDOM.HeaderInner>
              {getSuccessMessage !== null ? (
                <Button
                  label="Create New Candidate"
                  event={() => navigate("/candidate-creation")}
                  style={{ backgroundColor: "#1f80f7" }}
                />
              ) : null}
            </StyledDOM.HeaderContainer>
            <div className="filter-container">
              <div className="field-container">
                <div className="filter-item">
                  <DynamicTextField
                    type="text"
                    id="canName"
                    name={"canName"}
                    value={searchFormik.values.canName}
                    onChange={searchFormik.handleChange}
                    placeholder={"Candidate Name"}
                    icon={SearchIcon}
                  />
                </div>
                <div className="filter-item">
                  <DynamicTextField
                    type="text"
                    id="canEmail"
                    name={"canEmail"}
                    value={searchFormik.values.canEmail}
                    onChange={searchFormik.handleChange}
                    placeholder={"Candidate Email"}
                    icon={SearchIcon}
                  />
                </div>
                <div className="filter-item">
                  <DynamicSelectInput
                    placeholder="Country"
                    options={CountryOptions}
                    name="country"
                    value={
                      CountryOptions.find(
                        (option) => option.value === searchFormik.values.country
                      )?.title || ""
                    }
                    onChange={(event) => {
                      const selectedCountry = CountryOptions.find(
                        (option) => option.title === event.target.value
                      );
                      searchFormik.setFieldValue(
                        "country",
                        selectedCountry?.value || ""
                      );
                    }}
                    wrapperStyle={{
                      width: "235px",
                    }}
                  />
                </div>
              </div>
              <div className="filter-button-container">
                <Button
                  event={handleFilter}
                  label="Search"
                  backgroundColor="#843A79"
                  // border="1px solid #843A79"
                  color="#fff"
                  style={{ padding: "8px 10px" }}
                  iconSrc={buttonSearchIcon}
                  iconPosition="left"
                />
                <Button
                  event={searchFormik.resetForm}
                  label="Clear"
                  backgroundColor="#ffff"
                  border="1px solid #843A79"
                  hoverBorder="1px solid #843A79"
                  color="#633a84"
                  style={{ padding: "8px 10px" }}
                  iconSrc={buttonClearIcon}
                  iconPosition="left"
                />
              </div>
            </div>
            <div className="table-outer">
              {getSuccessMessage !== null ? (
                <Table
                  data={IRMTFilterData}
                  columns={column}
                  pagination
                  paginationServer
                  paginationTotalRows={IRMTFilterData.length}
                  onChangePage={(page) => setCurrentPage(page - 1)}
                  fixedHeader
                  fixedHeaderScrollHeight={"500px"}
                  highlightOnHover
                />
              ) : (
                <StyledDOM.InfoBannerContainer className="information-banner">
                  <StyledDOM.InfoRightContainer className="information-right-container">
                    <StyledDOM.InfoBannerHeading className="information-header">
                      Candidate Search
                    </StyledDOM.InfoBannerHeading>
                    <StyledDOM.InfoBannerContent className="information-content">
                      Using candidate search, you can locate an existing
                      candidate by entering any of the following parameters:
                    </StyledDOM.InfoBannerContent>
                    <StyledDOM.InfoBannerContentSpan className="information-span">
                      {" "}
                      (Candidate Name, Candidate Email,Candidate Country)
                    </StyledDOM.InfoBannerContentSpan>
                    <div>
                      <StyledDOM.InfoNoteContainer className="information-note">
                        Note: You can create a new candidate if your candidate
                        search yields no relevant candidates.
                      </StyledDOM.InfoNoteContainer>
                      <Button
                        label="Create New Candidate"
                        event={handleCreateCandidate}
                        style={{ backgroundColor: "#1f80f7" }}
                      />
                    </div>
                  </StyledDOM.InfoRightContainer>
                  <StyledDOM.InfoLeftContainer className="information-left-container">
                    <StyledAnimationContainer className="login-animation">
                      <Player
                        autoplay
                        loop
                        src={CandidateSearchAnimation}
                        style={{ height: "60vh", width: "100%" }}
                      >
                        <Controls
                          visible={false}
                          buttons={["play", "repeat", "frame", "debug"]}
                        />
                      </Player>
                    </StyledAnimationContainer>
                  </StyledDOM.InfoLeftContainer>
                </StyledDOM.InfoBannerContainer>
              )}
            </div>
          </>
          <Footerfile />
        </>
      )}
    </>
  );
}

export default CandidateMapping;
