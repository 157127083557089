import React from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import styled from "@emotion/styled";

export const StyledAnimationContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
});

export const StyledCreateAccountLeftContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "center",
  background: "linear-gradient(105deg, #7f5ad2e0 -3.19%, #815bd6c7 225.57%);",
  "@media screen and (min-width:300px) and (max-width:768px)": {
    display: "none",
  },
  "@media screen and (min-width:769px) and (max-width:920px)": {
    display: "none",
  },
});

function LeftSideBanner(props) {
  const {

    playerSrc={}
  } = props || {};
  return (
    <StyledCreateAccountLeftContainer className="create-account-left-container">

      <StyledAnimationContainer className="login-animation">
        <Player autoplay loop src={playerSrc}  style={{ height: "100vh", width: "650px" }}>
          <Controls
            visible={false}
            buttons={["play", "repeat", "frame", "debug"]}
          />
        </Player>
      </StyledAnimationContainer>
   
    </StyledCreateAccountLeftContainer>
  );
}

export default LeftSideBanner;
