import React, { useContext } from "react";
import Button from "@mui/material/Button";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Controller, useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../styles/otp.css";
// import "../OTP Page/otp.css";
import Lock from "../image/login-icon.png";
import config from "../../config";
import * as url from "../assets/helpers/url_helper";
import axios from "axios";
import { Toast } from "../../Common/ToastSnackBar/ToastSnackBar.js";
import Loader from "../Loader/Loader";
import Footer from "../Login/Footer.js";
import { DynamicContext } from "../../Context/DynamicContext.js";
const Otppage = (props) => {
  const DynamicProps = useContext(DynamicContext);
  const { email, isLoading, setIsLoading } = DynamicProps || {};
  const [Otp, setOtp] = React.useState();
  const [isResendDisabled, setIsResendDisabled] = React.useState(true);
  const [resendTimer, setResendTimer] = React.useState(30);
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      otp: Otp,
    },
  });
  const onSubmit = async () => {
    try {
      const response = await axios.post(
        config.apiEndpoint + url.OTP_VERIFICATION,
        {
          userEmail: email,
          otp: Otp,
        }
      );
      console.log(response);
      setIsLoading(true);
      localStorage.setItem("token", response.data.token);
      Toast("success", response.data.message);
      setTimeout(() => {
        setIsLoading(false);
        localStorage.setItem("email", email);
        navigate("/dashboard");
      }, 2000);
    } catch (error) {
      console.error(error.message);
      Toast("error", error.response.data.message);
      setIsLoading(false);
    }
  };

  const handleOtpChange = (value, onChange) => {
    if (/^\d*$/.test(value)) {
      onChange(value);
      setOtp(value);
    }
  };
  React.useEffect(() => {
    let interval = null;
    if (isResendDisabled) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isResendDisabled]);

  React.useEffect(() => {
    if (resendTimer === 0) {
      setIsResendDisabled(false);
      setResendTimer(30);
    }
  }, [resendTimer]);
  const handleResendOtp = async () => {
    try {
      const response = await axios.post(config.apiEndpoint + url.OTP_GENERATE, {
        userEmail: email,
      });
      config.token = response.data.token;
      console.log(response);
      setIsResendDisabled(true);
      Toast("success", response.data.message);
    } catch (error) {
      Toast("error", error.response.data.message);
    }
  };
  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    );
  }
  const openSnackbar = () => {
    if (!Otp) {
      Toast("error", "Please Enter your OTP");
      setIsLoading(false);
    }
  };
  return (
    <div className="otppage">
      <form onSubmit={handleSubmit(onSubmit)} className="otpform">
        <div className="otp_box">
          <div className="vylogo">
            <img src={Lock} alt="logo" style={{ marginBottom: "1rem" }} />
          </div>
          <h1 className="otpheading">Enter the OTP send to your mail</h1>
          <div className="otp_field">
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Controller
                  name="otp"
                  control={control}
                  rules={{ validate: (value) => value && value?.length === 4 }}
                  render={({ field, fieldState }) => (
                    <Box>
                      <MuiOtpInput
                        className="otp-input"
                        sx={{ gap: 2 }}
                        {...field}
                        length={4}
                        onChange={(value) =>
                          handleOtpChange(value, field.onChange)
                        }
                        TextFieldsProps={{
                          autoComplete: "off",
                          type: "text",
                        }}
                        onKeyDown={(evt) =>
                          (evt.key === "e" || evt.key === "E") &&
                          evt.preventDefault()
                        }
                        autoFocus={true}
                      />
                      <div>
                        <Button
                          className="resendotp"
                          variant="text"
                          sx={{ mt: 2 }}
                          onClick={handleResendOtp}
                          disabled={isResendDisabled}
                          color="success"
                        >
                          {isResendDisabled
                            ? `Resend OTP in ${resendTimer}s`
                            : "Resend OTP?"}
                        </Button>
                      </div>
                    </Box>
                  )}
                />
              </Grid>
              <button
                // color="secondary"
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                className="verifybutton"
                onClick={openSnackbar}
              >
                Submit
              </button>
              <Footer></Footer>
            </Grid>
          </div>
        </div>
      </form>
    </div>
  );
};
export default Otppage;
