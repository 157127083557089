import React, { useState } from "react";
import "./Textfield.css";
import eyeopen from "../../Component/image/eye-open.svg";
import eyeclose from "../../Component/image/eyes-close.svg";
import styled from "@emotion/styled";

export const StyledErrorMessage = styled("div")({
  color: "red",
  fontSize: "14px",
  marginTop: "6px",
});

export const StyledImage = styled("img")`
  height: 20px;
  width: 20px;
  position: absolute;
  ${({ iconAlignment }) =>
    iconAlignment === "right"
      ? `
        right: 10px;
      `
      : `
        left: 10px;
      `}
  top: 8px;
  cursor: pointer;
`;

const DynamicTextField = ({
  label,
  name,
  value,
  icon = null,
  iconAlignment = "left",
  onChange,
  placeholder,
  type = "text",
  required = false,
  showPasswordToggle = false,
  error = "",
  errorMessage = "",
  readOnly = false,
  style = {},
  className = "textfield-input",
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="textfield-container">
      {label && (
        <label htmlFor={name} className="textfield-label">
          {label} {required && <span className="required-asterisk">*</span>}
        </label>
      )}
      <div
        className="textfield-input-container"
        style={{ position: "relative" }}
      >
        {icon && iconAlignment === "left" && (
          <StyledImage src={icon} alt="icon" iconAlignment={iconAlignment} />
        )}
        <input
          id={name}
          name={name}
          type={showPasswordToggle && isPasswordVisible ? "text" : type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          className={className}
          readOnly={readOnly}
          style={{
            ...style,
            paddingLeft: iconAlignment === "left" && icon ? "35px" : undefined,
            paddingRight:
              iconAlignment === "right" && icon ? "35px" : undefined,
          }}
        />
        {icon && iconAlignment === "right" && (
          <StyledImage src={icon} alt="icon" iconAlignment={iconAlignment} />
        )}
        {showPasswordToggle && type === "password" && (
          <img
            src={isPasswordVisible ? eyeopen : eyeclose}
            alt="Toggle Password Visibility"
            className="password-toggle-icon"
            onClick={handleTogglePasswordVisibility}
            style={{
              right: "10px",
              position: "absolute",
              top: "8px",
              cursor: "pointer",
            }}
          />
        )}
      </div>

      {error && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </div>
  );
};

export default DynamicTextField;
