import React from "react";
import Footerimg from "../image/Footer.png";
import { Typography } from "@mui/material";
// import "../styles/footer.css"
const Footer = () => {
  return (
    <div className="footer" style={{ display: "flex", marginTop: "2vw" }}>
      <Typography variant="body2" className="powered">
        {" "}
        Powered by
      </Typography>
      <img src={Footerimg} alt="Powered By Logo" style={{ width: "100px" }} />
    </div>
  );
};
export default Footer;
