import React from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import styled from "@emotion/styled";
import ErrorAnimation from "../assets/Json/404-page-animation.json";
import Button from "../../Common/Button/Button";
import { useNavigate } from "react-router-dom";
export const StyledAnimationContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
});

const ErrorContentsDiv = styled("div")({
  textAlign: "center",
  // marginTop: "-2vw",
});

const ErrorText = styled("h2")({
  fontSize: "22px",
  fontWeight: "700",
  color: "#684084",
});

const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
function Errorpage() {
  const navigate = useNavigate();
  return (
    <div className="main">
      <StyledAnimationContainer className="login-animation">
        <Player
          autoplay
          loop
          src={ErrorAnimation}
          style={{ height: "80vh", width: "80%" }}
        >
          <Controls
            visible={false}
            buttons={["play", "repeat", "frame", "debug"]}
          />
        </Player>
      </StyledAnimationContainer>
      <ErrorContentsDiv className="error-content-div">
        <ErrorText className="exist">
          The Page You're looking for doesn't exist
        </ErrorText>
        <ButtonContainer>
          <Button
            label="Go To Home"
            event={() => navigate("/dashboard")}
            backgroundColor="#ffff"
            border="1px solid #633a84"
            color="#633a84"
            hoverbackgroundColor="#633a84"
            hoverColor="white"
          />
        </ButtonContainer>
      </ErrorContentsDiv>
    </div>
  );
}

export default Errorpage;
