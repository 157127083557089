import React, { useEffect, useState, useRef, useContext } from "react";
import "../styles/FileList.css";
import { Grid } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import * as url from "../assets/helpers/url_helper";
import config from "../../config";
import { Toast } from "../../Common/ToastSnackBar/ToastSnackBar";
import Header from "../header/Header";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "../../Component/image/delete-icon.png";
import Loader from "../Loader/Loader";
import downloadFileFromS3 from "../Aws-File/AwsConfig";
import Footerfile from "../Dashboard/Footerfile";
import Table from "../../Common/Table/Table";
import ModalPopup from "../../Common/Content-Dialog/ModalPopup";
import DeleteAnimation from "../assets/Json/delete-animation.json";
import TextField from "../../Common/TextField/Textfield";
import { useFormik } from "formik";
import SelectInput from "../../Common/Select-Input/SelectInput";
import Button from "../../Common/Button/Button";
import SearchIcon from "../image/search-icon.png";
import CustomTooltip from "../../Common/Tooltip/Tooltip";
import buttonSearchIcon from "../image/button-search.png";
import buttonClearIcon from "../image/button-clear.png";
import CandidateList from "../image/candidate-list-active.png";
import { useNavigate } from "react-router-dom";
import { DynamicContext } from "../../Context/DynamicContext";

function FileList() {
  const DynamicProps = useContext(DynamicContext);
  const navigate = useNavigate();
  const {
    setEdit,
    setEditData,
    SetCanId,
    isLoading,
    setIsLoading,
    setTokenExpired,
  } = DynamicProps || {};

  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [selectedButton, setSelectedButton] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [FileData, setFileData] = useState();
  const [FileExpiry, setFileExpiry] = useState();
  const [DeletePopupopen, setDeletePopupopen] = useState(false);
  const [deleteCandidateId, setDeleteCandidateId] = useState(null);
  const [IRMTFilterData, setIRMTFilterData] = useState([]);
  const [getSuccessMessage, setGetSuccessMessage] = useState(null);
  const [loaderIRMT, setloaderIRMT] = useState(false);
  const userEmail = localStorage.getItem("email");
  console.log("IRMTFilterData", IRMTFilterData);
  console.log("LocalFilteredData", filteredData);

  const formik = useFormik({
    initialValues: {
      canName: "",
      canEmail: "",
      country: "",
    },
  });

  const CountryOptions = [
    { title: "USA", value: 1 },
    { title: "Canada", value: 10 },
  ];
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    return `${formattedDate}, `;
  }
  console.log(scroll, FileExpiry);
  //fetch datas
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          config.apiEndpoint + url.CANDIDATE_TABLE_LIST,

          {
            params: { user: userEmail },
          }
        );
        const fetchedData = response.data.data;
        setData(fetchedData);
        setFilteredData(fetchedData);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Token expired"
        ) {
          setTokenExpired(true);
        }
      }
    };

    fetchData();
  }, [setIsLoading, setTokenExpired, userEmail, DeletePopupopen]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  if (isLoading || loaderIRMT) {
    return <Loader />;
  }
  const getCountryName = (countryCode) => {
    switch (countryCode) {
      case 1:
        return <p>USA</p>;
      case 10:
        return <p>Canada</p>;

      default:
        return "";
    }
  };
  //get filter
  const handleFilter = async () => {
    if (
      formik.values.canName ||
      formik.values.canEmail ||
      formik.values.country !== ""
    ) {
      setloaderIRMT(true);
      try {
        const response = await axios.get(
          "https://vyhire.com/ajax" + url.CANDIDATE_FILTER_VALUES,
          {
            params: {
              user_key: userEmail,
              can_name: formik.values.canName,
              can_email: formik.values.canEmail,
              country: formik.values.country,
            },
          }
        );
        const filteredData = response?.data?.results?.candidates;
        const message = response.data.results.message;
        setIRMTFilterData(filteredData);
        // setFilderedCandidateData(filteredData);
        setGetSuccessMessage(message);
        setFilteredData(data);
        setCurrentPage(0);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Token expired"
        ) {
          setTokenExpired(true);
        }
      } finally {
        setloaderIRMT(false);
        formik.resetForm();
      }
    } else {
      Toast("error", "Please enter any search values");
    }
  };
  //popup data
  const FileDetailsPopup = async (scrollType, buttonType, Canid, Expiry) => {
    setSelectedButton(buttonType);
    setScroll(scrollType);
    setFileExpiry(Expiry);

    try {
      const response = await axios.get(
        config.apiEndpoint + url.CANDIDATE_FILE_DETAILS,
        { params: { can_id: Canid, fileType: buttonType } }
      );
      const fetchedData = response.data.data;
      console.log(fetchedData);
      setFileData(fetchedData);

      setOpen(true);
    } catch (error) {
      console.error(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Token expired"
      ) {
        setTokenExpired(true);
      }
    }
  };
  //Delete Details
  const handleDelete = async (can_id) => {
    try {
      await axios.delete(config.apiEndpoint + url.CANDIDATE_DATA_DELETE, {
        params: { can_id: can_id },
      });

      Toast("success", "Data deleted successfully");
    } catch (error) {
      console.error(error);
      Toast("error", "Failed to delete data");
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Token expired"
      ) {
        setTokenExpired(true);
      }
    } finally {
      handleDeletePopupClose();
    }
  };
  const handleDeletePopupOpen = (can_id) => {
    setDeletePopupopen(true);
    setDeleteCandidateId(can_id);
  };

  const handleDeletePopupClose = () => {
    setDeletePopupopen(false);
    setDeleteCandidateId(null);
  };
  //Edit Details
  const EditDetails = async (Canid) => {
    try {
      const response = await axios.get(
        config.apiEndpoint + url.CANDIDATE_DATA_EDIT,
        { params: { can_id: Canid } }
      );
      const fetchedData = response.data.data;
      navigate("/candidate-creation");
      console.log(fetchedData);
      setEdit(true);
      SetCanId(Canid);
      setEditData(fetchedData);
      setOpen(true);
    } catch (error) {
      console.error(error);
      setEdit(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Token expired"
      ) {
        setTokenExpired(true);
      }
    }
  };
  const handleDownload = async (fileKey) => {
    try {
      if (!fileKey) {
        console.log("File key is missing.");
        return;
      }

      const downloadLink = await downloadFileFromS3([fileKey]);

      if (downloadLink) {
        const anchor = document.createElement("a");
        anchor.href = downloadLink;
        anchor.target = "_blank";
        anchor.style.display = "none";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      } else {
        console.log("Failed to generate download link.");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const column = [
    {
      name: "Name",
      selector: (row) => (
        <CustomTooltip title={row?.can_name}>
          <span>{row?.can_name}</span>
        </CustomTooltip>
      ),
    },
    {
      name: "Email",
      selector: (row) => (
        <CustomTooltip title={row?.can_email}>
          <span>{row?.can_email}</span>
        </CustomTooltip>
      ),
    },
    {
      name: "Phone Number",
      selector: (row) => <span>{row?.can_phone}</span>,
    },
    {
      name: "Skill",
      selector: (row) => (
        <CustomTooltip title={row?.can_skill}>
          <span>{row?.can_skill}</span>
        </CustomTooltip>
      ),
    },
    {
      name: "Job",
      selector: (row) => (
        <CustomTooltip title={row?.job}>
          <span>{row?.job}</span>
        </CustomTooltip>
      ),
    },
    {
      name: "Role",
      selector: (row) => (
        <CustomTooltip title={row?.role}>
          <span>{row?.role}</span>
        </CustomTooltip>
      ),
    },
    {
      name: "Call Stage",
      selector: (row) => (
        <CustomTooltip title={row?.call_stage}>
          <span>{row?.call_stage}</span>
        </CustomTooltip>
      ),
    },
    {
      name: "Hire Mode",
      selector: (row) => (
        <CustomTooltip title={row?.call_stage}>
          <span>{row?.call_stage}</span>
        </CustomTooltip>
      ),
    },
    {
      name: "Project Start Date",
      selector: (row) => <span>{row?.Project_joined_date}</span>,
    },
    {
      name: "Project End Date",
      selector: (row) => <span>{row?.cpe_project_end_date}</span>,
    },
  ];

  const ListTableColumn = [
    {
      name: "Name",
      selector: (row) => (
        <CustomTooltip title={row?.can_name}>
          <span>{row?.can_name}</span>
        </CustomTooltip>
      ),
    },
    {
      name: "Email",
      selector: (row) => (
        <CustomTooltip title={row?.can_email}>
          <span>{row?.can_email}</span>
        </CustomTooltip>
      ),
    },
    {
      name: "Project Start Date",
      selector: (row) => <span>{formatDate(row?.project_start_date)}</span>,
    },
    {
      name: "Vendor Name",
      selector: (row) => (
        <CustomTooltip title={row?.vy_vendor_name}>
          <span>{row?.vy_vendor_name}</span>
        </CustomTooltip>
      ),
    },
    {
      name: "Country",
      selector: (row) => (
        <CustomTooltip title={row?.country_code}>
          <span>{getCountryName(row?.country_code)}</span>
        </CustomTooltip>
      ),
    },
    {
      name: "H1B",
      selector: (row) => <span>{row?.H1BVendorornot}</span>,
    },
    {
      name: "H1B Name",
      selector: (row) => <span>{row?.H1Bvendorname}</span>,
    },
    {
      name: "I9",
      selector: (row) => (
        <Button
          // className="i9-icon"
          event={() => FileDetailsPopup("paper", "i9", row?.can_id, true)}
          style={{ padding: "5px 15px", fontWeight: 700 }}
          label="I9"
          border="2px dotted #ff0d19a8"
          backgroundColor="white"
          color="#ff0d19a8"
          hoverbackgroundColor="#ff0d19a8"
          hoverColor="white"
        />
      ),
    },
    {
      name: "Msa",
      selector: (row) => (
        <Button
          // className="i9-icon"
          event={() => FileDetailsPopup("paper", "msa", row.can_id, false)}
          label="MSA"
          style={{ padding: "5px 15px", fontWeight: 700 }}
          border="2px dotted #4AA1B5"
          backgroundColor="white"
          color="#4AA1B5"
          hoverbackgroundColor="#4AA1B5"
          hoverColor="white"
        />
      ),
    },
    {
      name: "W9",
      selector: (row) => (
        <Button
          // className="i9-icon"
          event={() => FileDetailsPopup("paper", "w9", row.can_id, false)}
          label="W9"
          style={{ padding: "5px 15px", fontWeight: 700 }}
          border="2px dotted #51A452"
          backgroundColor="white"
          color="#51A452"
          hoverbackgroundColor="#51A452"
          hoverColor="white"
        />
      ),
    },
    {
      name: "Visa",
      selector: (row) => (
        <Button
          // className="i9-icon"
          event={() => FileDetailsPopup("paper", "visa", row.can_id, false)}
          label="Visa"
          style={{ padding: "5px 15px", fontWeight: 700 }}
          border="2px dotted #7e00ffc4"
          backgroundColor="white"
          color="#7e00ffc4"
          hoverbackgroundColor="#7e00ffc4"
          hoverColor="white"
        />
      ),
    },
    {
      name: "Coi",
      selector: (row) => (
        <Button
          // className="i9-icon"
          event={() => FileDetailsPopup("paper", "coi", row.can_id, true)}
          label="Coi"
          style={{ padding: "5px 15px", fontWeight: 700 }}
          border="2px dotted #3d89f7cf"
          backgroundColor="white"
          color="#3d89f7cf"
          hoverbackgroundColor="#3d89f7cf"
          hoverColor="white"
        />
      ),
    },
    {
      name: "Csd",
      selector: (row) => (
        <Button
          // className="i9-icon"
          event={() =>
            FileDetailsPopup("paper", "counter_sign", row?.can_id, false)
          }
          label="Csd"
          style={{ padding: "5px 15px", fontWeight: 700 }}
          border="2px dotted #ef6d14"
          backgroundColor="white"
          color="#ef6d14"
          hoverbackgroundColor="#ef6d14"
          hoverColor="white"
        />
      ),
    },
    {
      name: "Edit",
      selector: (row) => (
        <EditIcon
          className="edit-icon"
          onClick={() => EditDetails(row.can_id)}
        ></EditIcon>
      ),
    },
    {
      name: "Delete",
      selector: (row) => (
        <img
          src={DeleteIcon}
          alt="DeleteIcon"
          className="delete-icon"
          onClick={() => handleDeletePopupOpen(row.can_id)}
        />
      ),
    },
  ];
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  return (
    <>
      <>
        <div className="file-mainsection">
          <Header />
          <Grid container className="file-content">
            <Grid item xs={12} lg={12} sm={12}>
              <div className="candidate-heading">
                <img
                  src={CandidateList}
                  alt="CandidateList"
                  className="candidate-list-icon"
                />
                <h2>Candidate Details</h2>
              </div>

              <div className="filter-container">
                <div className="field-container">
                  <div className="filter-item">
                    <TextField
                      type="text"
                      id="canName"
                      name={"canName"}
                      value={formik.values.canName}
                      onChange={formik.handleChange}
                      placeholder={"Candidate Name"}
                      icon={SearchIcon}
                    />
                  </div>
                  <div className="filter-item">
                    <TextField
                      type="text"
                      id="canEmail"
                      name={"canEmail"}
                      value={formik.values.canEmail}
                      onChange={formik.handleChange}
                      placeholder={"Candidate Email"}
                      icon={SearchIcon}
                    />
                  </div>
                  <div className="filter-item">
                    <SelectInput
                      placeholder="Country"
                      options={CountryOptions}
                      name="country"
                      value={
                        CountryOptions.find(
                          (option) => option.value === formik.values.country
                        )?.title || ""
                      }
                      onChange={(event) => {
                        const selectedCountry = CountryOptions.find(
                          (option) => option.title === event.target.value
                        );
                        formik.setFieldValue(
                          "country",
                          selectedCountry?.value || ""
                        );
                      }}
                      wrapperStyle={{
                        width: "235px",
                      }}
                    />
                  </div>
                </div>
                <div className="filter-button-container">
                  <Button
                    event={handleFilter}
                    label="Search"
                    backgroundColor="#843A79"
                    // border="1px solid #843A79"
                    color="#fff"
                    style={{ padding: "8px 10px" }}
                    iconSrc={buttonSearchIcon}
                    iconPosition="left"
                  />
                  <Button
                    event={formik.resetForm}
                    label="Clear"
                    backgroundColor="#ffff"
                    border="1px solid #843A79"
                    hoverBorder="1px solid #843A79"
                    color="#633a84"
                    style={{ padding: "8px 10px" }}
                    iconSrc={buttonClearIcon}
                    iconPosition="left"
                  />
                </div>
              </div>
              <div className="table-outer">
                {getSuccessMessage !== null ? (
                  <Table
                    data={IRMTFilterData}
                    columns={column}
                    pagination
                    paginationServer
                    paginationTotalRows={filteredData.length}
                    onChangePage={(page) => setCurrentPage(page - 1)}
                    fixedHeader
                    fixedHeaderScrollHeight={"500px"}
                    highlightOnHover
                  />
                ) : (
                  <>
                    {" "}
                    <Table
                      data={filteredData}
                      columns={ListTableColumn}
                      pagination={true}
                      paginationServer
                      paginationPerPage={rowsPerPage}
                      onChangePage={(page) => setCurrentPage(page - 1)}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      fixedHeader
                      fixedHeaderScrollHeight={"500px"}
                      highlightOnHover
                    />
                  </>
                )}
              </div>
              <div>
                {open ? (
                  <div className="table-popup-container">
                    <div className="table-popup-outer">
                      <div className="table-popup-heading">
                        <h2>
                          {" "}
                          {selectedButton
                            .replace(/_/g, " ")
                            .charAt(0)
                            .toUpperCase() +
                            selectedButton.slice(1).toLowerCase()}{" "}
                          Details
                        </h2>
                        <CancelIcon
                          className="close-icon"
                          onClick={handleClose}
                        ></CancelIcon>
                      </div>
                      <div className="table-popup">
                        <div className="table-popup-div">
                          {FileData &&
                            FileData.map((data, index) => {
                              return (
                                <>
                                  <h3 className="candidate-title-popup">
                                    Candidate Infomation
                                  </h3>
                                  <div className="candidate-head">
                                    <h4 className="popup_can_name">
                                      Name : {data.can_name}
                                    </h4>
                                    <h4 className="can_name_table">
                                      {data.file_from_date === null
                                        ? `Visa Expiry Date : Data Not Found`
                                        : null}
                                      {data.file_from_date &&
                                        `Visa Expiry Date: ${formatDate(
                                          data.file_from_date
                                        )}`}
                                    </h4>
                                    <h4>
                                      {data.file_expiry_date === null
                                        ? `File Expiry Date : Data Not Found`
                                        : null}
                                      {data.file_expiry_date &&
                                        `File Expiry Date : ${formatDate(
                                          data.file_expiry_date
                                        )}`}
                                    </h4>

                                    <h4>
                                      {data.visa_expiry_date === null
                                        ? `Visa Expiry Date : Data Not Found`
                                        : null}
                                      {data.visa_expiry_date &&
                                        `Visa Expiry Date: ${formatDate(
                                          data.visa_expiry_date
                                        )}`}
                                    </h4>

                                    <h4>
                                      {data.file_signed_date === null
                                        ? `MSA sigened date : Data Not Found`
                                        : null}
                                      {data.file_signed_date &&
                                        `MSA sigened date: ${formatDate(
                                          data.file_signed_date
                                        )}`}
                                    </h4>
                                  </div>
                                  <h3 className="candidate-title-popup">
                                    Candidate Remark Status
                                  </h3>
                                  <div>
                                    <h4 className="remark">
                                      {data.remarks
                                        ? `Remark :${data.remarks}`
                                        : `Remark : Data Not Found`}
                                    </h4>
                                  </div>
                                  <h3 className="candidate-title-popup">
                                    Candidate File Download
                                  </h3>
                                  {selectedButton === "i9" && (
                                    <>
                                      {FileData && data.file_key === null ? (
                                        <div>
                                          <p className="table-err-msg">
                                            No File to Download candidate{" "}
                                            {selectedButton} File
                                          </p>
                                        </div>
                                      ) : (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <div className="file-download">
                                          <p
                                            className="file-icon-text"
                                            key={index}
                                          >
                                            Download the Candidate{" "}
                                            {selectedButton
                                              .replace(/_/g, " ")
                                              .toUpperCase()}{" "}
                                            File
                                            <span
                                              className="download-section"
                                              onClick={() =>
                                                handleDownload(
                                                  FileData[0].file_key
                                                )
                                              }
                                            >
                                              Click here
                                            </span>
                                          </p>
                                        </div>
                                      )}

                                      {/* i9Exhibit_B_Files */}
                                      {FileData &&
                                      data.exhibit_b_file_key === null ? (
                                        <>
                                          <p className="table-err-msg">
                                            No File To Download {selectedButton}{" "}
                                            ExhibitB File
                                          </p>
                                        </>
                                      ) : (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <>
                                          <p
                                            className="file-icon-text"
                                            key={index}
                                          >
                                            Download the Candidate I9 ExhibitB
                                            File
                                            <span
                                              className="download-section"
                                              onClick={() =>
                                                handleDownload(
                                                  FileData[0].exhibit_b_file_key
                                                )
                                              }
                                            >
                                              Click here
                                            </span>
                                          </p>
                                        </>
                                      )}
                                    </>
                                  )}
                                  {/* MSA File download */}
                                  {selectedButton === "msa" && (
                                    <>
                                      {FileData && data.file_key === null ? (
                                        <>
                                          <p className="table-err-msg">
                                            No File to Download candidate{" "}
                                            {selectedButton} File
                                          </p>
                                        </>
                                      ) : (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <>
                                          <p
                                            className="file-icon-text"
                                            key={index}
                                          >
                                            Download the Candidate{" "}
                                            {selectedButton.replace(/_/g, " ")}{" "}
                                            File
                                            <span
                                              className="download-section"
                                              onClick={() =>
                                                handleDownload(
                                                  FileData[0].file_key
                                                )
                                              }
                                            >
                                              Click here
                                            </span>
                                          </p>
                                        </>
                                      )}
                                      {FileData &&
                                      data.exhibit_a_file_key === null ? (
                                        <>
                                          <p className="table-err-msg">
                                            No File to Download Exhibit-A/PO
                                            File
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <p
                                            className="file-icon-text"
                                            key={index}
                                          >
                                            Download the Candidate Exhibit-A/PO
                                            File
                                            <span
                                              className="download-section"
                                              onClick={() =>
                                                handleDownload(
                                                  FileData[0].exhibit_a_file_key
                                                )
                                              }
                                            >
                                              Click here
                                            </span>
                                          </p>
                                        </>
                                      )}
                                    </>
                                  )}

                                  {/* W9 File Download */}

                                  {selectedButton === "w9" && (
                                    <>
                                      {FileData && data.file_key === null ? (
                                        <>
                                          <p className="table-err-msg">
                                            No File to Download candidate{" "}
                                            {selectedButton} File
                                          </p>
                                        </>
                                      ) : (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <>
                                          <p
                                            className="file-icon-text"
                                            key={index}
                                          >
                                            Download the Candidate{" "}
                                            {selectedButton.replace(/_/g, " ")}{" "}
                                            File
                                            <span
                                              onClick={() =>
                                                handleDownload(
                                                  FileData[0].file_key
                                                )
                                              }
                                            >
                                              Click here
                                            </span>
                                          </p>
                                        </>
                                      )}
                                      {FileData && data.vc_file_key === null ? (
                                        <>
                                          <p className="table-err-msg">
                                            No File to Download VOID Check File
                                          </p>
                                        </>
                                      ) : (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <>
                                          <p
                                            className="file-icon-text"
                                            key={index}
                                          >
                                            Download the Candidate VOID Check
                                            File
                                            <span
                                              className="download-section"
                                              onClick={() =>
                                                handleDownload(
                                                  FileData[0].vc_file_key
                                                )
                                              }
                                            >
                                              Click here
                                            </span>
                                          </p>
                                        </>
                                      )}
                                      {FileData &&
                                      data.ach_file_key === null ? (
                                        <>
                                          <p className="table-err-msg">
                                            No File to Download ACH-Upload File
                                          </p>
                                        </>
                                      ) : (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <>
                                          <p
                                            className="file-icon-text"
                                            key={index}
                                          >
                                            Download the Candidate ACH-Upload
                                            File
                                            <span
                                              onClick={() =>
                                                handleDownload(
                                                  FileData[0].ach_file_key
                                                )
                                              }
                                            >
                                              Click here
                                            </span>
                                          </p>
                                        </>
                                      )}
                                    </>
                                  )}
                                  {/* Visa file Download */}
                                  {selectedButton === "visa" && (
                                    <>
                                      {FileData && data.file_key === null ? (
                                        <>
                                          <p className="table-err-msg">
                                            No File to Download {selectedButton}{" "}
                                            File
                                          </p>
                                        </>
                                      ) : (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <>
                                          <p
                                            className="file-icon-text"
                                            key={index}
                                          >
                                            Download the Candidate{" "}
                                            {selectedButton.replace(/_/g, " ")}{" "}
                                            File
                                            <span
                                              onClick={() =>
                                                handleDownload(
                                                  FileData[0].file_key
                                                )
                                              }
                                            >
                                              Click here
                                            </span>
                                          </p>
                                        </>
                                      )}
                                    </>
                                  )}
                                  {/* Coi File Download */}
                                  {selectedButton === "coi" && (
                                    <>
                                      {FileData && data.file_key === null ? (
                                        <>
                                          <p className="table-err-msg">
                                            No File to Download {selectedButton}{" "}
                                            File
                                          </p>
                                        </>
                                      ) : (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <>
                                          <p
                                            className="file-icon-text"
                                            key={index}
                                          >
                                            Download the Candidate{" "}
                                            {selectedButton.replace(/_/g, " ")}{" "}
                                            File
                                            <span
                                              onClick={() =>
                                                handleDownload(
                                                  FileData[0].file_key
                                                )
                                              }
                                            >
                                              Click here
                                            </span>
                                          </p>
                                        </>
                                      )}
                                      {FileData &&
                                      data.cis_file_key === null ? (
                                        <>
                                          <p className="table-err-msg">
                                            No File to Download Contact info
                                            Sheet file
                                          </p>
                                        </>
                                      ) : (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <>
                                          <p
                                            className="file-icon-text"
                                            key={index}
                                          >
                                            Download the Contact info Sheet file
                                            <span
                                              onClick={() =>
                                                handleDownload(
                                                  FileData[0].cis_file_key
                                                )
                                              }
                                            >
                                              Click here
                                            </span>
                                          </p>
                                        </>
                                      )}
                                    </>
                                  )}
                                  {/* Countersign File Download */}
                                  {selectedButton === "counter_sign" && (
                                    <>
                                      {FileData && data.file_key === null ? (
                                        <>
                                          <p className="table-err-msg">
                                            No File to Download Counter Signed
                                            File
                                          </p>
                                        </>
                                      ) : (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <>
                                          <p
                                            className="file-icon-text"
                                            key={index}
                                          >
                                            Download the Candidate{" "}
                                            {selectedButton.replace(/_/g, " ")}{" "}
                                            File
                                            <span
                                              onClick={() =>
                                                handleDownload(
                                                  FileData[0].file_key
                                                )
                                              }
                                            >
                                              Click here
                                            </span>
                                          </p>
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              {DeletePopupopen ? (
                <ModalPopup
                  isOpen={DeletePopupopen}
                  onClose={handleDeletePopupClose}
                  showPlayer={true}
                  playerSrc={DeleteAnimation}
                  confirmLabel="Delete"
                  cancelLabel={"Cancel"}
                  title={"Are you sure ?"}
                  onConfirm={() => handleDelete(deleteCandidateId)}
                  message={"Do you want to delete this candidate"}
                  normalButton={"true"}
                />
              ) : null}
            </Grid>
          </Grid>
          <Footerfile />
        </div>
      </>
    </>
  );
}

export default FileList;
