import React from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import TimeOutAnimation from "../assets/Json/time-out-animation.json";
import styled from "@emotion/styled";
import Button from "../../Common/Button/Button";
import { useNavigate } from "react-router-dom";
import { DynamicContext } from "../../Context/DynamicContext";
import { useContext } from "react";

const StyledExpiryContainer = styled("div")({
  width: "55%",
  margin: "100px auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  background: "#fff",
  boxShadow: "1px 1px 5px #828282",
  padding: "20px",
  borderRadius: "15px",
});
const StyledAnimationContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
});
const ErrorContentsDiv = styled("div")({
  textAlign: "center",
});
const ErrorText = styled("h2")({
  fontSize: "22px",
  fontWeight: "700",
  color: "#684084",
});
const ButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
function ExpiryScreen() {
  const DynamicProps = useContext(DynamicContext);
  const { setotppopup, setTokenExpired } = DynamicProps || {};
  const navigate = useNavigate();

  const clearsession = () => {
    localStorage.clear("email");
    setotppopup(false);
    setTokenExpired(false);
    navigate("/");
  };
  return (
    <StyledExpiryContainer className="expiry-login-container">
      <StyledAnimationContainer className="login-animation">
        <Player
          autoplay
          loop
          src={TimeOutAnimation}
          style={{ height: "42vh", width: "100%" }}
        >
          <Controls
            visible={false}
            buttons={["play", "repeat", "frame", "debug"]}
          />
        </Player>
      </StyledAnimationContainer>
      <ErrorContentsDiv className="error-content-div">
        <ErrorText>"Your session is expired. Please login again"</ErrorText>
        <ButtonContainer className="button-container">
          <Button
            label="Login Again"
            event={clearsession}
            backgroundColor="#ffff"
            border="1px solid #633a84"
            color="#633a84"
            hoverbackgroundColor="#633a84"
            hoverColor="white"
          />
        </ButtonContainer>
      </ErrorContentsDiv>
    </StyledExpiryContainer>
  );
}

export default ExpiryScreen;
