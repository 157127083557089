import React, { useContext, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import CustomTooltip from "../../Common/Tooltip/Tooltip";
import vylogo from "../image/vy-logo.png";
import "../styles/Header.css";
import Home from "../image/home.png";
import HomeActive from "../image/home-active.png";
import CandidateActive from "../image/candidate-active.png";
import Candidate from "../image/candidate-icon.png";
import CandidateCreation from "../image/candidate-creation.png";
import CandidateCreationActive from "../image/candidate-creation-active.png";
import CandidateListActive from "../image/candidate-list-active.png";
import CandidateList from "../image/candidate-list.png";
import Logout from "../image/logout.png";
import arrowUp from "../image/arrow-up.png";
import activeArrowUp from "../image/active-arrow-up.png";
import arrowDown from "../image/arrow-down.png";
import activeArrowDown from "../image/active-arrow-down.png";
import CandidateMapping from "../image/candidate-mapping.png";
import CandidateMappingActive from "../image/candidate-mapping-active.png";
import { DynamicContext } from "../../Context/DynamicContext";
const Header = () => {
  const DynamicProps = useContext(DynamicContext);
  const { setMappingData, setEditData } = DynamicProps || {};
  const navigate = useNavigate();
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(false);

  const clearSession = () => {
    localStorage.clear();
    navigate("/login");
  };

  const menuItems = [
    { label: "Home", path: "/", icon: Home, activeIcon: HomeActive },
    {
      label: "Candidate",
      path: "",
      icon: Candidate,
      activeIcon: CandidateActive,
      dropdown: [
        {
          label: "Candidate Mapping",
          path: "/candidate-mapping",
          icon: CandidateMapping,
          activeIcon: CandidateMappingActive,
        },
        {
          label: "Candidate Creation",
          path: "/candidate-creation",
          icon: CandidateCreation,
          activeIcon: CandidateCreationActive,
        },
        {
          label: "Candidate List",
          path: "/candidate-list",
          icon: CandidateList,
          activeIcon: CandidateListActive,
        },
      ],
    },
  ];

  const getActiveLink = () => {
    const { pathname } = location;
    if (pathname.includes("/dashboard")) return "Home";
    if (pathname.includes("/candidate-creation")) return "Candidate";
    if (pathname.includes("/candidate-mapping")) return "Candidate";
    if (pathname.includes("/candidate-list")) return "Candidate";
    return "Home";
  };

  const pageActiveLinker = getActiveLink();

  const toggleDropdown = () => {
    setOpenDropdown((prevState) => !prevState);
  };

  const handleClearData = () => {
    setEditData([]);
    setMappingData(null);
  };
  return (
    <div className="navbar">
      <div className="logo">
        <NavLink to="/">
          <img src={vylogo} alt="Logo" className="vysystemlogo" />
        </NavLink>
      </div>

      <div className="menu-container">
        {menuItems.map((item, index) => {
          const isActiveMenu = pageActiveLinker === item.label;

          return (
            <div
              key={index}
              className={`menu-item ${item.dropdown ? "dropdown" : ""} ${
                isActiveMenu ? "active" : ""
              }`}
            >
              <div
                className={`menu-link ${isActiveMenu ? "active" : ""}`}
                onClick={item.dropdown ? toggleDropdown : null}
              >
                <img
                  src={isActiveMenu ? item.activeIcon : item.icon}
                  alt={item.label}
                  className="menu-icon"
                />
                <NavLink
                  to={item.path}
                  className={`navlink ${isActiveMenu ? "active" : ""}`}
                >
                  {item.label}
                </NavLink>
                {item.dropdown && (
                  <img
                    src={
                      openDropdown
                        ? isActiveMenu
                          ? activeArrowUp
                          : arrowUp
                        : isActiveMenu
                        ? activeArrowDown
                        : arrowDown
                    }
                    alt="Dropdown Arrow"
                    className="dropdown-arrow"
                  />
                )}
              </div>
              {item.dropdown && openDropdown && (
                <div className="dropdown-menu">
                  {item.dropdown.map((subItem, subIndex) => {
                    const isActiveSubMenu = location.pathname === subItem.path;

                    return (
                      <div
                        key={subIndex}
                        className={`submenu-item ${
                          isActiveSubMenu ? "active-submenu" : ""
                        }`}
                      >
                        <img
                          src={
                            isActiveSubMenu ? subItem.activeIcon : subItem.icon
                          }
                          alt={subItem.label}
                          className="submenu-icon"
                        />
                        <NavLink
                          to={subItem.path}
                          className={`submenu-link ${
                            isActiveSubMenu ? "active-submenu-link" : ""
                          }`}
                          onClick={handleClearData}
                        >
                          {subItem.label}
                        </NavLink>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
        <div className="logout-container">
          <CustomTooltip title="Logout" bgColor="#6f24a1" color="#fff">
            <img
              src={Logout}
              alt="Logout"
              onClick={clearSession}
              className="logout-icon"
            />
          </CustomTooltip>
        </div>
      </div>
    </div>
  );
};

export default Header;
