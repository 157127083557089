import React from "react";
import AppRouter from "./Routes/AppRouter";
import { DynamicProvider } from "./Context/DynamicContext";
function App() {
  return (
    <div>
      <DynamicProvider>
        <AppRouter />
      </DynamicProvider>
    </div>
  );
}

export default App;
