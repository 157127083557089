import React from "react";
import styled from "@emotion/styled";
import CountUp from "react-countup";
import Button from "../Button/Button";

const CardContainer = styled.div`
  width: 385px;
  height: 150px;
  background: ${(props) =>
    props.backgroundImage
      ? `url(${props.backgroundImage})`
      : props.backgroundColor || "#ffffff"};
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-size: 550px;
  cursor: pointer;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 60%;
`;

const Header = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  height: 60%;
`;

const Count = styled.div`
  font-size: 3.5rem;
  font-weight: bold;
  width: 100%;
`;

const SideImage = styled.img`
  width: 40%;
  height: 100%;
`;
const ViewButton = styled(Button)({
  backgroundColor: "#ffffff",
  color: "#333",
  border: "none",
  borderRadius: "8px",
  padding: "8px 12px",
  // fontSize: "1rem",
  cursor: "pointer",
  marginTop: "auto",
  width: "50%",
});
const DashboardCard = ({
  header,
  backgroundColor,
  backgroundImage,
  count,
  sideImage,
  onClick,
  label = "View",
  event,
  hoverbackgroundColor,
  hoverColor,
  hoverBorder = "2px solid #ffffff",
}) => {
  return (
    <CardContainer
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      onClick={onClick}
    >
      <CardContent>
        <Header>{header}</Header>
        <Count>
          <CountUp start={0} end={count} duration={5} separator="," />
        </Count>
        <ViewButton
          label={label}
          event={event}
          hoverbackgroundColor={hoverbackgroundColor}
          hoverColor={hoverColor}
          hoverBorder={hoverBorder}
        />
      </CardContent>
      <SideImage src={sideImage} alt={`${header} illustration`} />
    </CardContainer>
  );
};

export default DashboardCard;
