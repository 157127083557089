import React, { useContext, useEffect } from "react";
import axios from "axios";
import * as url from "../assets/helpers/url_helper";
import config from "../../config";
import "../styles/dashboard.css";
import Header from "../header/Header";
import CancelIcon from "@mui/icons-material/Cancel";
import candidatelogo from "../image/candidate-bg.png";
import completelogo from "../image/upload-completed.png";
import incompletelogo from "../image/upload-incompleted.png";
import expirylogo from "../image/expire-bg.png";
import Footerfile from "../Dashboard/Footerfile";
import DashboardCard from "../../Common/Cards/DashboardCards";
import OrangeCard from "../image/orange-card.png";
import BlueCard from "../image/blue-card.png";
import GreenCard from "../image/green-card.png";
import YellowCard from "../image/yellow-card.png";
import Button from "../../Common/Button/Button";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import styled from "@emotion/styled";
import CandidateCreateAnimation from "../assets/Json/candidate-creation-animation.json";
import CandidateListAnimation from "../assets/Json/candidatelist-animation.json";
import BackgroundImage from "../image/man-with-computer.png";
import { useNavigate } from "react-router-dom";
import Table from "../../Common/Table/Table";
import fileExpiry from "../image/file-expiry.png";
import daysLeft from "../image/days-left.png";
import CustomTooltip from "../../Common/Tooltip/Tooltip";
import { DynamicContext } from "../../Context/DynamicContext";

const StyledAnimationContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
});

const PopupButton = styled(Button)({
  border: "none",
  borderRadius: "8px",
  padding: "8px 12px",
  cursor: "pointer",
  marginTop: "auto",
  width: "100%",
});
const fileLogos = [candidatelogo, incompletelogo, completelogo, expirylogo];
const backgroundImages = [BlueCard, OrangeCard, GreenCard, YellowCard];
function Dashboard() {
  const DynamicProps = useContext(DynamicContext);
  const navigate = useNavigate();
  const { setTokenExpired, setMappingData, setEditData } = DynamicProps || {};
  const [data, setData] = React.useState([]);
  const [ExpiryDate, setExpiryDate] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  document.body.classList.add("salmon");
  const { i9 = [], coi = [], visa = [] } = ExpiryDate;
  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <CustomTooltip title={row.can_name}>{row.can_name}</CustomTooltip>
      ),
      sortable: true,
    },
    {
      name: "Candidate Email",
      selector: (row) => (
        <CustomTooltip title={row.can_email}>{row.can_email}</CustomTooltip>
      ),
      sortable: true,
    },
    {
      name: "File Type",
      selector: (row) => (
        <PopupButton
          label={row.file_type}
          backgroundColor={popupButtonsColor(row.file_type)}
        />
      ),
    },
    {
      name: "Remaining Days",
      selector: (row) =>
        row.remaining_days <= 0 ? (
          <div className="fileExpiry-container">
            <img
              src={fileExpiry}
              alt="fileExpiry"
              className="fileExpiry-icon"
            />
            <span>File Expired</span>
          </div>
        ) : (
          <div className="days-left-container">
            <img src={daysLeft} alt="daysLeft" className="daysLeft-icon" />
            <span>{`${row.remaining_days} ${
              row.remaining_days === 1 ? "Day Left" : "Days Left"
            }`}</span>
          </div>
        ),
    },
    {
      name: "Expiry Date",
      selector: (row) => formatDate(row.file_expiry_date),
    },
  ];
  console.log(data);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    return `${formattedDate} `;
  }

  const handleCreateCandidate = () => {
    navigate("/candidate-creation");
    setEditData([]);
    setMappingData(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          config.apiEndpoint + url.GET_COUNT_BY_DATA
        );
        const filteredData = response.data.data;
        console.log(response);
        setData(filteredData);
      } catch (error) {
        console.log(error);

        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Token expired"
        ) {
          setTokenExpired(true);
        }
      }
    };
    fetchData();
  }, [setTokenExpired]);
  const getExpiryDate = async () => {
    try {
      const response = await axios.get(
        config.apiEndpoint + url.GET_EXPIRY_DATE_COUNT
      );
      setOpen(true);
      const filteredData = response.data.data;
      console.log(filteredData);

      setExpiryDate(filteredData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const popupButtonsColor = (fileType) => {
    switch (fileType) {
      case "visa":
        return "#0098f3";
      case "i9":
        return "#ff8d2c";
      case "coi":
        return "#74bb49";
      default:
        return "#fffff";
    }
  };
  const cardButtonEvents = (fileType) => {
    switch (fileType) {
      case "Candidates":
        return () => {};
      case "File Upload Incompleted Candidates":
        return () => {};
      case "File Upload Completed Candidates":
        return () => {};
      case "Files to Expire":
        return getExpiryDate;
      default:
        return () => {};
    }
  };

  const buttonHoverBackGroundColor = (fileType) => {
    switch (fileType) {
      case "Candidates":
        return "#0098f3";
      case "File Upload Incompleted Candidates":
        return "#ff8d2c";
      case "File Upload Completed Candidates":
        return "#74bb49";
      case "Files to Expire":
        return "#f1b518";
      default:
        return "#fffff";
    }
  };
  const buttonHoverColor = (fileType) => {
    switch (fileType) {
      case "Candidates":
        return "#ffffff";
      case "File Upload Incompleted Candidates":
        return "#ffffff";
      case "File Upload Completed Candidates":
        return "#ffffff";
      case "Files to Expire":
        return "#ffffff";
      default:
        return "#ffffff";
    }
  };
  return (
    <div className="main-container">
      <div className="header">
        <Header />
        <div className="merge-container">
          <div className="left-container">
            <div className="username-container">
              <div className="inner-banner-container">
                <div className="text-container">
                  <h2 className="username-header">Hello,Nandhakumar Sri</h2>
                  <h3 className="username-sub-header">
                    Welcome To Vysystems Data Lake
                  </h3>
                </div>
                <img
                  src={BackgroundImage}
                  alt="BackgroundImage"
                  className="banner-background-image"
                />
              </div>
            </div>
            <div className="card-container">
              {data.map((item, index) => (
                <DashboardCard
                  key={index}
                  header={item.file_type}
                  backgroundColor=""
                  backgroundImage={
                    backgroundImages[index % backgroundImages.length]
                  }
                  count={item.count}
                  sideImage={fileLogos[index % fileLogos.length]}
                  event={cardButtonEvents(item.file_type)}
                  hoverbackgroundColor={buttonHoverBackGroundColor(
                    item.file_type
                  )}
                  hoverColor={buttonHoverColor(item.file_type)}
                />
              ))}
            </div>
          </div>
          <div className="right-container">
            <div className="quick-events">
              <div className="quick-card">
                <h3 className="quick-card-header">Create candidate</h3>
                <StyledAnimationContainer className="login-animation">
                  <Player
                    autoplay
                    loop
                    src={CandidateCreateAnimation}
                    style={{ height: "15vh", width: "100%" }}
                  >
                    <Controls
                      visible={false}
                      buttons={["play", "repeat", "frame", "debug"]}
                    />
                  </Player>
                </StyledAnimationContainer>
                <span className="quick-card-content">
                  You are free to create as many candidates as you like.
                </span>
                <Button
                  label="Create"
                  style={{ marginBottom: "5px" }}
                  event={handleCreateCandidate}
                />
              </div>
              <div className="quick-card">
                <h3 className="quick-card-header">List of candidate</h3>
                <StyledAnimationContainer className="login-animation">
                  <Player
                    autoplay
                    loop
                    src={CandidateListAnimation}
                    style={{ height: "20vh", width: "100%" }}
                  >
                    <Controls
                      visible={false}
                      buttons={["play", "repeat", "frame", "debug"]}
                    />
                  </Player>
                </StyledAnimationContainer>
                <span className="quick-card-content">
                  Please look over the list of applicants to locate the ideal
                  one.
                </span>
                <Button
                  label="Check"
                  style={{ marginBottom: "5px" }}
                  event={() => navigate("/candidate-list")}
                />
              </div>
            </div>
          </div>
        </div>

        {open ? (
          <div className="expiry-popup-container">
            <div className="expiry-popup-outer">
              <div className="expiry-popup-heading">
                <h2>Expiry Date Details</h2>
                <CancelIcon
                  className="close-icon"
                  onClick={handleClose}
                ></CancelIcon>
              </div>
              <div className="expiry-popup">
                <Table
                  columns={columns}
                  data={[...visa, ...i9, ...coi]}
                  pagination={true}
                  highlightOnHover={true}
                  fixedHeader={true}
                  fixedHeaderScrollHeight="400px"
                  className="expiry-data-table"
                />
              </div>
            </div>
          </div>
        ) : null}

        <Footerfile />
      </div>
    </div>
  );
}

export default Dashboard;
